import React from 'react'
import { Link } from 'react-router-dom'

const Limaimmigrate = () => {
    return (
        <div className='col-lg-8 col-xl-8 col-md-6 col-12'>
            <div className=''>
                <h4 className='cndntxtpp'>What is the LMIA program?</h4>
                <p className='cndtxtdiv'>The LMIA program is a part of the Temporary Foreign Worker Program (TFWP) that allows Canadian employers to hire foreign workers for jobs that cannot be filled by Canadian citizens or permanent residents. LMIA stands for Labour Market Impact Assessment, which is a document that an employer needs to obtain before hiring a foreign worker.A positive LMIA shows that there is a need for a foreign worker to fill the job and that no Canadian worker or permanent resident is available to do the job</p>
            </div>
            <div className=''>
                <h4 className='cndntxtppcntpp'>How to apply for the LMIA program?</h4>
                <p className='wystxtpp'>To apply for the LMIA program, an employer must follow these steps:</p>
                <ul className='ulxctctfg'>
                    <li className='exptxtpp'>
                        <span className='spnbvtxt'>Step 1:   </span>
                        Determine the eligibility and requirements for the LMIA program. There are different streams and categories under the LMIA program, such as high-wage, low-wage, agricultural, in-home caregiver, permanent residency, and seasonal agricultural worker program. Each stream and category have its own eligibility criteria, wage levels, recruitment efforts, and processing fees. The employer must choose the stream and category that best suits their needs and situation.  <Link to="/">
                        For more information, visit the official website of Employment and Social Development Canada (ESDC).</Link> </li>
                    <li className='exptxtpp'>
                        <span className='spnbvtxt'> Step 2:  </span>
                        Create an online profile and register the business on Job Bank. Job Bank is a free online tool that connects employers with job seekers. The employer must have a payroll account number associated with a business number issued by the Canada Revenue Agency (CRA) to create an online profile and register the business on Job Bank. The employer must also provide information about their skills, education, work experience, language ability, and other factors that affect their eligibility and ranking.  <Link to="/" >For more information, visit Job Bank’s page for employers.</Link>
                    </li>
                    <li className='exptxtpp'> <span className='spnbvtxt'> Step 3:  </span> Submit an LMIA application using the LMIA Online Portal. The LMIA Online Portal is a reliable and secure electronic tool that allows employers and third-party representatives (on behalf of employers) to submit an LMIA application to Service Canada. The employer must fill out the application form, upload the required documents, and pay the fees online. The employer must also provide proof of their business legitimacy and their recruitment efforts to hire Canadians, such as ads, resumes, and interview notes.
                        <Link to="/">For more information, visit the LMIA Online Portal resources page</Link> </li>
                    <li className='exptxtpp'> <span className='spnbvtxt'> Step 4 </span>Receive a decision on the LMIA application. Service Canada will review the LMIA application and verify the employer’s eligibility and admissibility. Service Canada will also assess the impact of hiring a foreign worker on the Canadian labour market, based on factors such as labour shortage, wage offer, economic benefits, and labour disputes. Service Canada will issue a positive or negative LMIA, or request additional information or clarification from the employer. The processing time for LMIA applications varies depending on the stream and category, and the volume of applications. <Link to="/"> For more information, visit the LMIA processing times page</Link> </li>
                </ul>
            </div>
        </div>
    )
}

export default Limaimmigrate
