import React from 'react'

const VisitorVisalist = () => {
    return (
        <>
            <div className='col-lg-8 col-xl-8 col-md-6 col-12'>
                <div className=''>
                    <h4 className='cndntxtpp'>What is Canada Visitor Visa ?</h4>
                    <p className='cndtxtdiv'>A Canada visitor visa, also known as a temporary resident visa, is an official document that shows that you meet the requirements to enter Canada for a short-term stay. You may need a visitor visa if you want to visit Canada for tourism, business, family, or transit purposes.</p>
                </div>
                <div className=''>
                    <h4 className='cndntxtppcntpp'>Who can apply for a Canada visitor visa?</h4>
                    <p className='wystxtpp'>You can apply for a Canada visitor visa if you:</p>
                    <ul className='ulxctctfg'>
                        <li className='exptxtpp'>
                            have a valid passport or travel document</li>
                        <li className='exptxtpp'>
                            are in good health and have no criminal or immigration-related convictions
                        </li>
                        <li className='exptxtpp'>
                            have enough money to support yourself during your stay in Canada
                        </li>
                        <li className='exptxtpp'>
                            have a clear purpose and plan for your visit</li>
                        <li className='exptxtpp'>
                            intend to leave Canada at the end of your authorized stay</li>
                        <li className='exptxtpp'>
                            meet any other requirements that may apply to your specific situation</li>

                    </ul>
                </div>
              
            </div>
        </>
    )
}

export default VisitorVisalist
