import React from 'react'
import InnerBanner from '../../Component/InnerBanner'
import ImmigrationList from '../../Component/ImmigrateProgramms/ImmigrationList'
import Allimmigrate from '../../Component/ImmigrateProgramms/Allimmigrate'
import student from "../../Image/student.jpg"
import Permanent from '../../Component/ImmigrateProgramms/Permanent'
import Residence from '../../Component/ImmigrateProgramms/Residence'
import ImmigrationForm from '../../Component/ImmigrateProgramms/ImmigrationForm'
const Permanentresidence = () => {
  return (
    <>
      <section className='immigrate_section_main'>
        <InnerBanner InBanHead="PERMANENT RESIDENCE" InBanLi="Permanent Residence" />
        <div className='cust_container'>
          <div className='row allctctdiv'>
            <Permanent/>
            {/* <Allimmigrate text="What is permanent residence in Canada?" /> */}
            <ImmigrationList />
          </div>
          <Residence/>  
        </div>
        <ImmigrationForm anstr="If You Want to apply for Canada Permanent Residence than connect with Desh Videsh Immigration Team for better guidance " 
        text="Becoming a permanent resident of Canada is a rewarding and meaningful step in your immigration journey. By obtaining permanent residence, you can enjoy the rights and responsibilities that come with it, and contribute to the diversity and prosperity of this great country." />
      </section>
    </>
  )
}

export default Permanentresidence
