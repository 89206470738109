import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./Component/Component.css";
import MainLayout from "./Layout/MainLayout";
import Home from "./View/Home/Index";
import About from "./View/About/Index";
import Blog from "./View/Blog/Blog";
import BlogDetails from "./View/Blog/BlogDetails";
import BookAppointment from "./View/BookAppoinment/BookAppointment"
import PreferDateTime from "./View/BookAppoinment/PreferDateTime"
// import Immigrate from "./View/ImmigratePrograms/Immigrate";
import Citizenship from "./View/ImmigratePrograms/Citizenship";
import Permanentresidence from "./View/ImmigratePrograms/Permanentresidence";
import Lmia from "./View/ImmigratePrograms/Lmia";
import Workpermit from "./View/ImmigratePrograms/Workpermit";
import SpousalSponsorship from "./View/ImmigratePrograms/SpousalSponsorship";
import VisitorVisa from "./View/ImmigratePrograms/VisitorVisa";
import StudentVisa from "./View/ImmigratePrograms/StudentVisa";
import Expressentry from "./View/ImmigratePrograms/Expressentry"
import Contact from "./View/Contact/Index";
import JoinWaitlist from "./View/BookAppoinment/JoinWaitlist"
import Gototop from "./Component/Scrolltotop/Gototop";
import Terms_Condition from "./View/BookAppoinment/Terms_Condition";
import ProceedToPay from "./View/BookAppoinment/ProceedToPay";
import CancelPolicy from "./View/BookAppoinment/CancelPolicy";
import UrgentPreferDateTime from "./View/BookAppoinment/UrgentPreferDateTime";
import UrgentJoinList from "./View/BookAppoinment/UrgentJoinList";
import UrgentTermsCondition from "./View/BookAppoinment/UrgentTermsCondition";
function App() {
  return (
    <>
      <BrowserRouter>
      
      <Gototop/>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blogdetails/:id" element={<BlogDetails />} />
            <Route path="/bookappointment" element={<BookAppointment />} />
            <Route path="/preferdateTime" element={<PreferDateTime />} />
            <Route path="/urgent-preferdateTime" element={<UrgentPreferDateTime />} />
            <Route path="/joinwaitlist" element={<JoinWaitlist />} />
            <Route path="/urgent-joinlist" element={<UrgentJoinList />} />
            <Route path="/terms-and-condition" element={<Terms_Condition />} /> 
            <Route path="/urgent-terms-and-condition" element={<UrgentTermsCondition />} /> 
            <Route path="/cancel-policy" element={<CancelPolicy />} /> 
            <Route path="/proceed-to-pay" element={<ProceedToPay />} /> 
            <Route path="/citizenship" element={<Citizenship />} />
            <Route path="/permanentresidence" element={<Permanentresidence />} />
            <Route path="/lmia" element={<Lmia />} />
            <Route path="/workpermit" element={<Workpermit />} />
            <Route path="/spousalSponsorship" element={<SpousalSponsorship />} />
            <Route path="/visitorVisa" element={<VisitorVisa />} />
            <Route path="/studentVisa" element={<StudentVisa />} />
            <Route path="/expressentry" element={<Expressentry />} />
            <Route path="/contact" element={<Contact/>}/>
          </Route>
          {/* UrgentTermsCondition */}

       
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
