import React from 'react'
const Expresslist = () => {
  return (
  <>
   <div className='col-lg-8 col-xl-8 col-md-6 col-12'>
                <div className=''>
                    <h4 className='cndntxtpp'>What is Express Entry?</h4>
                    <p className='cndtxtdiv'>Express Entry is an online system that manages applications for permanent residence in Canada from skilled workers who want to settle in Canada permanently and take part in its economy. Express Entry is the fastest and most popular immigration program under Canadian immigration1. It was introduced in January 2015 by the federal government, to replace the previous “first come, first served” process12.</p>
                </div>
                <div className=''>
                    <h4 className='cndntxtppcntpp'>How does Express Entry work?</h4>
                    <p className='wystxtpp'>Express Entry works in four steps:</p>
                    <ul className='ulxctctfg'>
                        <li className='exptxtpp'>
                            <span className='spnbvtxt'> Step 1:  </span>
                            Create your online profile. You need to provide information about your skills, education, work experience, language ability, and other factors that affect your eligibility and ranking. You also need to take a language test and get an education credential assessment, if applicable. You can also register with Job Bank, a free online tool that connects you with Canadian employers.</li>
                        <li className='exptxtpp'>
                            <span className='spnbvtxt'> Step 2: </span>
                            Enter the pool of candidates. Based on the information you provided, you will be assessed and given a score based on the Comprehensive Ranking System (CRS). The CRS is a point-based system that ranks candidates based on their human capital factors, such as age, education, language, work experience, and adaptability. You will also get additional points if you have a valid job offer, a provincial nomination, or Canadian education or work experience. You will be placed in a pool of candidates with other eligible applicants.
                        </li>
                        <li className='exptxtpp'> <span className='spnbvtxt'> Step 3: </span> Receive an invitation to apply. Every few weeks, the government will conduct a draw and invite the highest-ranking candidates from the pool to apply for permanent residence. The minimum score required to receive an invitation varies depending on the number of candidates and invitations available.You can check the latest draw results and CRS cut-off scores on the official website of Immigration, Refugees and Citizenship Canada (IRCC). If you receive an invitation, you will have 60 days to submit your completeapplication online. You will need to provide supporting documents, such as language test results, education assessments, job offers, police certificates, medical exams, and proof of funds.
                           </li>
                        <li className='exptxtpp'> <span className='spnbvtxt'> Step 4:  </span>Get a decision on your application. After you submit your application, IRCC will review it and verify your eligibility and admissibility. You may also be asked to provide additional information or attend an interview. The processing time for Express Entry applications is usually six months or less. If your application is approved, you will receive a confirmation of permanent residence and a visa, if applicable. You will then be able to travel to Canada and become a permanent resident.</li>
                    </ul>
                </div>
            </div>
  </>


  )
}

export default Expresslist
