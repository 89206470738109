import React, { useEffect, useState } from "react";
import "./BookAppointment.css";
import toast from "react-hot-toast";
import HomeServices from "../../Services/HomeServices";
import { Link } from "react-router-dom";
import countryCode from "../../Country_Code";
import countryName from "../../Country_Name";
const HowcanMeetForm = ({ sbmybtn }) => {
  const contactFeild = {
    mobileNumber: "",
    email: "",
    firstName: "",
    lastName: "",
    note: "",
    countryName: "",
    countryCode: "+1",
  };

  const [contactData, setContactData] = useState(contactFeild);
  const [countryData, setCountryData] = useState([]);
  const handleChange = (e) => {
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    fetchCountry();
  }, []);

  const fetchCountry = async () => {
    const res = await HomeServices.viewCountry();
    setCountryData(res?.data);
  };

  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneRegex = /^\d{10}$/;
  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      mobileNumber,
      email,
      firstName,
      lastName,
      note,
      countryName,
      countryCode,
    } = contactData;
    const isValid =
      mobileNumber?.trim() !== "" &&
      email?.trim() !== "" &&
      firstName?.trim() !== "" &&
      lastName?.trim() !== "" &&
      note?.trim() !== "" &&
      countryName?.trim() !== "" &&
      countryCode?.trim() !== "";
    const mobileNumberAsNumber = parseInt(contactData.mobileNumber, 10);
    if (isValid) {
      if (!email.match(mailFormat)) {
        toast.error("Please Enter Valid Email..!");
      } else if (!phoneRegex.test(mobileNumber)) {
        toast.error("Please Enter Valid Mobile Number..!");
      } else {
        let data = {
          mobileNumber: contactData?.mobileNumber,
          email: contactData?.email,
          firstName: contactData?.firstName,
          lastName: contactData?.lastName,
          note: contactData?.note,
          country: contactData?.countryName,
          mobileCode: contactData?.countryCode,
        };
        const res = await HomeServices.addContact(data);
        console.log("resCat", res);
        if (res && res.status) {
          toast.success("Contact Send Successfully");
        } else {
          toast.error(res.message);
        }
        setContactData({ ...contactData, mobileNumber: mobileNumberAsNumber });
        setContactData(contactFeild);
        console.log("hjgdafuj5654", contactData);
      }
    } else {
      toast.error("Please Fill All the Inputs..!");
    }
  };

  return (
    <>
      <div className="avaiPrefTextDiv">
        <p className="avaiPrefText">How can we contact you?</p>
      </div>
      <form action="" className="howCanWeForm">
        <div className="howFormInpDiv">
          <select
            name=""
            id=""
            className="howFormHlfInp"
            style={{ color: "#FF9C00" }}
            onChange={(e) => {
              setContactData({
                ...contactData,
                countryName: e.target.value,
              });
            }}
          >
            <option value="">Select Country</option>
            {countryName?.map((val) => {
              return (
                <>
                  <option key={val?.name} value={val?.name}>
                    <span className="country-name">{val?.name}</span> 
                  </option>
                </>
              );
            })}
          </select>
          <div className="howFormHlfInpInDiv howFormHlfInpMarge">
            <select
              name=""
              id=""
              className="howFormHlfInp"
              style={{ color: "#FF9C00", width: "100px", height: "42px", marginRight: "2px" }}
              onChange={(e) => {
                setContactData({
                  ...contactData,
                  countryCode: e.target.value,
                });
              }}
            >
              <option value="+1">+1</option>
              {countryCode?.map((val) => {
                return (
                  <>
                    <option value={val?.dial_code}>{val?.dial_code}</option>
                  </>
                );
              })}
            </select>
            <input
              type="text"
              name="mobileNumber"
              value={contactData?.mobileNumber}
              onChange={handleChange}
              placeholder="Mobile Phone"
              className="howFormInInp"
            />
          </div>
        </div>

        <div className="howFormInpDiv">
          <input
            type="text"
            name="email"
            onChange={handleChange}
            value={contactData?.email}
            placeholder="Email"
            className="howFormFullInp"
          />
        </div>
        <div className="howFormInpDiv">
          <input
            type="text"
            onChange={handleChange}
            name="firstName"
            value={contactData?.firstName}
            placeholder="First name"
            className="howFormHlfInp"
          />
          <input
            type="text"
            placeholder="Last name"
            className="howFormHlfInp howFormHlfInpMarge"
            onChange={handleChange}
            name="lastName"
            value={contactData?.lastName}
          />
        </div>
        <div>
          <textarea
            onChange={handleChange}
            name="note"
            value={contactData?.note}
            id=""
            cols=""
            rows="5"
            placeholder="Please Share anything that will help prepare for our meeting."
            className="howFormFullInp"
          ></textarea>
        </div>
        <div className="sbmtrt">
          <button className="sbmybtn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default HowcanMeetForm;
