import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import deshvideshimmigration from "../../src/Image/deshvideshimmigration.png"
import { Colorcontext } from '../Component/context/ColorContext';

const Header = () => {
  const colorData = useContext(Colorcontext);
  // console.log("ljhgh", colorData)

  const [shownavbar, setShowNavbar] = useState(false)
  const [stickyheader, setStickyheader] = useState(false);
  const [menuwh, setMenuwh] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!shownavbar)
  }
  function setFixed() {
    if (window.scrollY > 100) {
      setStickyheader(true)
    }
    else {
      setStickyheader(false)
    }
  }
  window.addEventListener("scroll", setFixed);

  const handleClick = () => {
    setMenuwh(!menuwh)
  }

  const menuRef = useRef(null); 
  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log("546jhvhgdt76ty",event)
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Click is outside the menu, close it
        setMenuwh(false);
      }
    };
  
    document.addEventListener("click", handleClickOutside);
  
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  
  return (
    <>
      {/* <div className={stickyheader ? 'main_header fixed' : 'main_header'}>
                <div className='container-fluid'>
                    <div className='logo_menu_main'>
                        <div className='lghdiv' >
                            <Link to="/" className='logo_hd_mn'>
                                DESH VIDESH IMMIGRATION
                            </Link>
                        </div>
                        <div className={`nav-elements ${shownavbar && 'active'}`}>

                            <div className='nvbsr nvbiu' onClick={handleShowNavbar}>
                                <i class="fa-solid fa-bars-staggered"></i>
                            </div>
                            <ul>
                                <li>
                                    <NavLink to="/" onClick={handleShowNavbar}>Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/about-us" onClick={handleShowNavbar}>About</NavLink>
                                </li>
                                <li>
                                    <div className='mnu_icn' onClick={handleClick}>
                                        <NavLink to="/" >
                                            Immigration Programs
                                            {menuwh ? <i classN="fas fa-caret-down"></i> : <i classN="fas fa-caret-up"></i>}
                                        </NavLink>
                                        {menuwh && (<ul className='sub_menu'>
                                            <li>
                                                <NavLink to="/citizenship" onClick={handleShowNavbar}>Citizenship</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/permanentresidence" onClick={handleShowNavbar}>Permanent Residence</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/expressentry" onClick={handleShowNavbar}>Express Entry</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/lmia" onClick={handleShowNavbar}>LMIA</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/workpermit" onClick={handleShowNavbar}>Work Permit</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/spousalSponsorship" onClick={handleShowNavbar}>Spousal Sponsorship</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/visitorVisa" onClick={handleShowNavbar}>Visitor Visa</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/studentVisa" onClick={handleShowNavbar}>Student Visa</NavLink>
                                            </li>
                                        </ul>
                                        )}
                                    </div>

                                </li>
                                <li>
                                    <NavLink to="/blog" onClick={handleShowNavbar}> Blog</NavLink>

                                </li>
                                <li>
                                    <NavLink to="/contact">Contact Us</NavLink>

                                </li>
                            </ul>
                        </div>

                        <div className="d-flex">
                            <div className='hdicon'> <i className="fa-brands fa-linkedin"></i> </div>
                            <div className='hdicon'><i className="fa-brands fa-facebook"></i></div>
                            <div className='hdicon'><i className="fa-brands fa-instagram"></i></div>
                            <div className='hdicon'><i className="fa-brands fa-twitter"></i></div>
                        </div>

                        <div className='nvbsr' onClick={handleShowNavbar}>
                            <i class="fa-solid fa-bars-staggered"></i>
                        </div>
                    </div>
                </div>
            </div> */}
      <div className={`main_header ${stickyheader ? "fixed" : ""} ${colorData.color ? "hdbkpt" : ""}`}>
        <div className="container-fluid">
          <div className="logo_menu_main">
            <div className="lghdiv">
              < Link to="/" className="logo_hd_mn">
                <img src={deshvideshimmigration} alt="..." />
                <p className='deshtxt'>DeshVidesh Immigration</p>
              </Link>
            </div>
            <div className={`nav-elements ${shownavbar && "active"}`}>
              {/* <div className="nvbsr nvbiu" onClick={handleShowNavbar}>
                <i class="fa-solid fa-bars-staggered"></i>
              </div> */}
              <ul>
                <li>
                  <NavLink to="/" onClick={handleShowNavbar}>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about-us" onClick={handleShowNavbar}>
                    About
                  </NavLink>
                </li>
                <li>
                  <div className="mnu_icn" onClick={handleClick} ref={menuRef}>
                    <NavLink >
                      Immigration Programs
                      {menuwh ? (
                        <i className="fas fa-caret-up"></i>
                      ) : (
                        <i className="fas fa-caret-down"></i>
                      )}
                    </NavLink>
                    {menuwh && (
                      <ul className="sub_menu">
                        <li>
                          <NavLink to="/citizenship" onClick={handleShowNavbar}>
                            Citizenship
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/permanentresidence"
                            onClick={handleShowNavbar}
                          >
                            Permanent Residence
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/expressentry"
                            onClick={handleShowNavbar}
                          >
                            Express Entry
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/lmia" onClick={handleShowNavbar}>
                            LMIA
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/workpermit" onClick={handleShowNavbar}>
                            Work Permit
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/spousalSponsorship"
                            onClick={handleShowNavbar}
                          >
                            Spousal Sponsorship
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/visitorVisa" onClick={handleShowNavbar}>
                            Canada Visitor Visa
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/studentVisa" onClick={handleShowNavbar}>
                            Student Visa
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </div>
                </li>
                <li>
                  <NavLink to="/blog" onClick={handleShowNavbar}>
                    {" "}
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact">Contact Us</NavLink>
                </li>
                <li>
                  <NavLink to="/bookappointment">Book An Appointment</NavLink>
                </li> 
                <li className="d-block  d-lg-none">
                  <NavLink to="https://www.youtube.com/@OfficialSujeetDahiya">You Tube<i class="fa-brands fa-youtube"></i></NavLink>
                </li>
                <li className="d-block  d-lg-none">
                  <NavLink to="https://www.instagram.com/deshvideshimmigration?igsh=N3BxdGExM3Bhdjdp">Instagram <i class="fa-brands fa-instagram"></i></NavLink>
                </li>
                <li className="d-block  d-lg-none">
                  <NavLink to="https://www.tiktok.com/@deshvideshimmigration?_t=8jpEWGBgCxi&_r=1">Tik Tok<i class="fa-brands fa-tiktok"></i></NavLink>
                </li>
              </ul>
            </div>

            <div className="d-lg-flex d-none"> 
              <Link to="https://www.youtube.com/@OfficialSujeetDahiya" className="hdicon">
              <i class="fa-brands fa-youtube"></i>
              </Link>
              <Link to="https://www.instagram.com/deshvideshimmigration?igsh=N3BxdGExM3Bhdjdp" className="hdicon">
                <i className="fa-brands fa-instagram"></i>
              </Link>
              <Link to="https://www.tiktok.com/@deshvideshimmigration?_t=8jpEWGBgCxi&_r=1" className="hdicon">
              <i class="fa-brands fa-tiktok"></i>
              </Link>
            </div>

            <div className="nvbsr" onClick={handleShowNavbar}>
              <i class="fa-solid fa-bars-staggered"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
