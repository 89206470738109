import React from "react";
import "./customLoader.css";
import { Oval, RotatingLines } from "react-loader-spinner";

const CustomLoader = ({ loading }) => {
  return (
    loading && (
      <div>
        <div className="white-background"></div>
        <div id="loader-myModal" className="loader-modal">
          <div className="loader-modal-content">
            <Oval
               visible={true}
               height="80"
               width="80"
               color="#ff9c00"
               ariaLabel="oval-loading"
               secondaryColor="#ccc"
               wrapperStyle={{}}
               wrapperClass=""
            />
          </div>
        </div>
      </div>
    )
  );
};

export default CustomLoader;
