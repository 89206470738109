import React from 'react'
import InnerBanner from '../../Component/InnerBanner'
import ImmigrationList from '../../Component/ImmigrateProgramms/ImmigrationList'
import Allimmigrate from '../../Component/ImmigrateProgramms/Allimmigrate'
import student from "../../Image/student.jpg"
import SpousalSponsorshiplist from '../../Component/ImmigrateProgramms/SpousalSponsorshiplist'
import ImmigrationForm from '../../Component/ImmigrateProgramms/ImmigrationForm'
const SpousalSponsorship = () => {
  return (
   <>
   <section className='immigrate_section_main'>
                <InnerBanner InBanHead="SPOUSAL SPONSORSHIP" InBanLi="Spousal Sponsorship" />
                <div className='cust_container'>
                    <div className='row allctctdiv'>
                        <SpousalSponsorshiplist/>
                        <ImmigrationList/>
                    
                    </div>
                </div>
                <ImmigrationForm anstr="If You Want to apply for Spousal Sponsorship in Canada than connect with Desh Videsh Immigration Team for better guidance "/>
            </section>
   </>
  )
}

export default SpousalSponsorship
