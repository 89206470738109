import React from 'react'
import InnerBanner from '../../Component/InnerBanner'
import ImmigrationList from '../../Component/ImmigrateProgramms/ImmigrationList'
// import Allimmigrate from '../../Component/ImmigrateProgramms/Allimmigrate'
// import student from "../../Image/student.jpg"
import Workimmigrate from '../../Component/ImmigrateProgramms/Workimmigrate'
import ImmigrationForm from '../../Component/ImmigrateProgramms/ImmigrationForm'
const Workpermit = () => {
  return (
    <>
      <section className='immigrate_section_main'>
        <InnerBanner InBanHead="WORKPERMIT" InBanLi="Workpermit" />
        <div className='cust_container'>
          <div className='row allctctdiv'>
            <Workimmigrate />
            <ImmigrationList />
          </div>
        </div>
        <ImmigrationForm text="If You Want to apply for Canada Work Permit than connect with Desh Videsh Immigration Team for better guidance." />
      </section>
    </>

  )
}

export default Workpermit
