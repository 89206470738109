import React from 'react'
import ImmigrationDesc from './ImmigrationDesc'
import sidebarcanada from "../../Image/sidebarcanada.jpg"
const Allimmigrate = () => {
    return (
        <>
            <div className='col-lg-8 col-xl-8 col-md-6 col-12'>
                <div className='immigrateimgmain'>
                    {/* <img src={img} alt="..." /> */}
                </div>
                <div className=''>
                    {/* <h4 className='imgtxtdivhh'>{name}</h4> */}
                    <p className='imgrtdttxt'>Why become a Canadian citizen?</p>
                    <p className='anscitip'>Canada is a country that welcomes immigrants from all over the world. As a permanent resident, you already enjoy many benefits of living in Canada, such as access to health care, education, and social services. But becoming a Canadian citizen can give you even more advantages, such as:</p>
                </div>
                <div className='mrlftppdiv'>
                    <ul>
                        <li className='lstctctpp'>
                        The right to vote and run for political office. As a citizen, you can have a say in how the country is governed and who represents you at the local, provincial, and federal levels. You can also participate in political parties and campaigns, or even run for office yourself.
                        </li>
                        <li className='lstctctpp'>
                        The ability to travel with a Canadian passport. A Canadian passport is one of the most powerful passports in the world, allowing you to visit more than 150 countries without a visa. You can also travel to the United States easily with a valid passport and no visa. A Canadian passport also gives you access to consular services and protection when you are abroad.
                        </li>
                        <li className='lstctctpp'>
                        The security of holding a status that is not easy to lose or relinquish. As a citizen, you do not have to worry about losing your status or being asked to leave Canada for any reason. You can also keep your citizenship even if you live outside Canada for a long time, as long as you maintain your ties to the country. You can also be a dual citizen, meaning you can hold another citizenship without giving up your Canadian one.
                        </li>
                        <li className='lstctctpp'>
                        The opportunity to apply for jobs that require citizenship. Some jobs in Canada, especially those related to the government or security, are only open to Canadian citizens. By becoming a citizen, you can expand your career options and pursue your professional goals.
                        </li>
                    </ul>
                </div>

            </div>
        </>
    )
}

export default Allimmigrate
