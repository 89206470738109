import React from 'react'
import InnerBanner from '../../Component/InnerBanner'
import ImmigrationList from '../../Component/ImmigrateProgramms/ImmigrationList'
import Allimmigrate from '../../Component/ImmigrateProgramms/Allimmigrate'
import student from "../../Image/student.jpg"
import Studentlist from '../../Component/ImmigrateProgramms/Studentlist'
import ImmigrationForm from '../../Component/ImmigrateProgramms/ImmigrationForm'
const StudentVisa = () => {
  return (
    <section className='immigrate_section_main'>
    <InnerBanner InBanHead="STUDENT VISA" InBanLi="Student Visa" />
    <div className='cust_container'>
        <div className='row allctctdiv'>
            <Studentlist/>
            <ImmigrationList />
           
        </div>
    </div>
    <ImmigrationForm anstr="If You Want to apply for Canada Student Visa than connect with Desh Videsh Immigration Team for better guidance."/>
</section>
  )
}

export default StudentVisa
