import React from 'react'
import { Link } from 'react-router-dom';
import IstBannerImage from "../../Image/IstBannerImage.jpg";
import CanadaWorkPermitBanner from "../../Image/CanadaWorkPermitBanner.jpg";
import bannerslid6 from "../../Image/bannerslid6.jpg";
import DisccovertheBestImmigrationPlanBanner from "../../Image/DisccovertheBestImmigrationPlanBanner.jpg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const Bannerslide = () => {
  var settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // fade: true,
    autoplaySpeed: 2000,
    // cssEase: "ease-in-out"
  };
  const banner = [
    {
      image: IstBannerImage,
      text: "You can Book an Appointment in just 60 seconds - Find out the Best Immigration Options for you.",
      // call: +123456678,
      heading:"Canada Immigration & Citizenship Consultants"
    },
    {
      image: CanadaWorkPermitBanner,
      text: "You can Book an Appointment in just 60 seconds - Find out the Best Immigration Options for you",
      // call: +123456678,
      heading:"Canada Work Permit"
    },
    {
      image: bannerslid6,
      text: "You can Book an Appointment in just 60 seconds - Find out the Best Immigration Options for you",
      // call: +123456678,
      heading:"Canada Study Permit become a student."
    },
    {
      image: DisccovertheBestImmigrationPlanBanner,
      text: "You can Book an Appointment in just 60 seconds - Find out the Best Immigration Options for you",
      // call: +123456678,
      heading:"Get Matched and Discover Immigration Programs."
    },
  ];
  return (
    <section className="banner_sld">
      <Slider {...settings}>
        {banner.map((item, index) => {
          return (
            <div className="banner_sld" key={index}>
              <img src={item.image} alt="..." />
              <div className="banner_sid_Overlay">
                <div className="cust_container">
                  <div className="bannerCustInDiv">
                    <div className="banner_dtls_txt">
                      <h4 className='textheadimgbaner'>{item.heading}</h4>
                      <p className="lorText">{item.text}</p>
                      {/* <p className="call_txt">call Us Today : {item.call}</p> */}
                    </div>
                  </div>

                  <div className="bktanttdyDiv">
                    <Link className="bktanttdy" to="/bookappointment">
                      BOOK AN APPOINTMENT TODAY
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </section>
  )
}

export default Bannerslide
