import React, { useEffect, useState } from "react";
import InnerBanner from "../../Component/InnerBanner";
import BlogCard from "../../Component/BlogCard";
import blg1 from "../../Image/blg1.png";
import blg2 from "../../Image/blg2.png";
import toast from "react-hot-toast";
import HomeServices from "../../Services/HomeServices";
import { Link, useNavigate } from "react-router-dom";
import CustomLoader from "../../loader/CustomLoader";

function Blog() {
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState();
  const [isLoading, setLoading] = useState(true);

  console.log("dghvahcag5745ajdbfjda", blogData);

  const fetchData = async () => {
    try {
      const res = await HomeServices.getBlog();
      if (res && res.status) {
        console.log("getBlogResponse", res);
        setBlogData(res);
      } else {
        // toast.error(res.message);
      }
    } catch {
      toast.error("server error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // routing with dynamic param id
  const handelMoreDetails = (data) => {
    navigate("/blogdetails/" + data?._id);
    // console.log(data, "details");
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  return (
    <section className="blogSection">
      <CustomLoader loading={isLoading} />

      <InnerBanner InBanHead="Blog Us" InBanLi="Blog" />
      <div className="cust_container mt-4">
        <div className="row">
          {blogData?.data &&
            blogData?.data
              ?.slice()
              .reverse()
              .map((value) => {
                const date = new Date(value?.createdAt);
                const month = monthName[date.getMonth()];
                return (
                  <div className="col-xl-4 col-md-6 col-sm-8 col-12 m-auto">
                    <div className="blgMainDiv">
                      <div className="blg_img_hd">
                        <img src={value?.image1} alt="..." />
                        <div className="dateInDiv">
                          <div className="dateDayMonDiv">
                            <p className="dateDay">
                              {new Date(value?.createdAt)
                                .getDate()
                                .toString()
                                .padStart(2, "0")}
                            </p>
                            <p className="dateMonth">{month}</p>
                          </div>
                          <p className="dateYear">
                            {new Date(value?.createdAt).getFullYear()}
                          </p>
                        </div>
                      </div>
                      <div className="blgdivtxt">
                        <p className="blgtxtpp">{value?.title}</p>
                        <h5
                          className="blgtxthhh"
                          dangerouslySetInnerHTML={{
                            __html: value?.description,
                          }}
                        ></h5>
                        <div className="mre_btn_divv">
                          <button
                            type="button"
                            onClick={() => handelMoreDetails(value)}
                            className="mre_btn"
                          >
                            More Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </section>
  );
}

export default Blog;
