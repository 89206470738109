import { useEffect } from "react";
import { useLocation } from "react-router-dom";
function Gototop() {
  const routePath = useLocation();
  console.log('routePath', routePath.pathname)

  useEffect(() => {
    onTop()
  }, [routePath.pathname]);

  const onTop = () => {
    // window.scrollTo(0, 0);
    // let element = document.getElementById("root")
    // element.scrollIntoView({ behavior: 'smooth' });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  return null;
}

export default Gototop
