import React from 'react'

const Residence = () => {
    return (
        <> 
        <section className='residntmain'>
              <div className='othprmntdivflx'>
                <div className='othprmntdiv'>
                    <p className='txtothprgms'>There are other programs and categories that you can apply for permanent residence in Canada, such as:</p>
                    <ul className='ullistpp'>
                        <li className='othlstdipp'>Agri-Food Pilot</li>
                        <li className='othlstdipp'>Atlantic Immigration Pilot</li>
                        <li className='othlstdipp'>Business Immigration</li>
                        <li className='othlstdipp'>Caregivers</li>
                        <li className='othlstdipp'>Humanitarian and Compassionate Grounds</li>
                        <li className='othlstdipp'>Protected Persons and Convention Refugees</li>
                        <li className='othlstdipp'>Quebec Immigration</li>
                        <li className='othlstdipp'>Rural and Northern Immigration Pilot</li>
                        <li className='othlstdipp'>Self-Employed Persons</li>
                        <li className='othlstdipp'>Start-Up Visa</li>
                        <li className='othlstdipp'>Temporary Resident to Permanent Resident Pathway</li>
                    </ul>
                </div>
                <div className='cndivdffg'>
                    <h4 className='cndatrpp'> What are the benefits of permanent residence in Canada? </h4>
                    <p className='ptxtcppas'>As a permanent resident, you can enjoy many benefits and rights that Canada offers, such as:</p>
                    <ul className='ullistpp'>
                        <li className='othlstdipp'>The right to live, work, or study anywhere in Canada</li>
                        <li className='othlstdipp'>The right to free education up to the age of 18 in the Canadian public school system</li>
                        <li className='othlstdipp'>The right to access health care, social services, and other benefits</li>
                        <li className='othlstdipp'>The right to apply for Canadian citizenship after meeting certain criteria</li>
                        <li className='othlstdipp'>The right to get protection under Canadian law and the Canadian Charter of Rights and Freedoms</li>
                        <li className='othlstdipp'>The right to travel with a Canadian permanent resident card or a permanent resident travel document</li>
                    </ul>
                </div>
            </div>

            <div className='prmntrsd'>
                <p className='pscvyu'>However, as a permanent resident, you also have some obligations and limitations, such as:</p>
                <ul className='ullistpp'>
                    <li className='othlstdipp'>You must pay taxes and respect all Canadian laws at the federal, provincial, and municipal levels</li>
                    <li className='othlstdipp'>You must meet the residency obligation of being in Canada for at least 730 days in the last five years</li>
                    <li className='othlstdipp'>You must renew your permanent resident card every five years or apply for a permanent resident travel document if you are outside Canada</li>
                    <li className='othlstdipp'>You cannot vote or run for political office or hold some jobs that require a high-level security clearance</li>
                    <li className='othlstdipp'>You can lose your permanent resident status if you are convicted of a serious crime, fail to meet the residency obligation, or become a Canadian citizen</li>
                  
                </ul>
            </div>
        </section>
          
        </>


    )
}

export default Residence
