import React from 'react'
import InnerBanner from '../../Component/InnerBanner';
import "./Immigrate.css"
// import student from "../../Image/student.jpg"
import ImmigrationList from '../../Component/ImmigrateProgramms/ImmigrationList';
import Allimmigrate from '../../Component/ImmigrateProgramms/Allimmigrate';
import ImmigrationDesc from '../../Component/ImmigrateProgramms/ImmigrationDesc';
// import ImmigrationDesc from '../../Component/ImmigrateProgramms/ImmigrationDesc';


import { Link } from 'react-router-dom';
import ImmigrationForm from '../../Component/ImmigrateProgramms/ImmigrationForm';
const Citizenship = () => {
    return (
        <>
            <section className='immigrate_section_main'>
                <InnerBanner InBanHead="CITIZENSHIP" InBanLi="citizenship" />
                <div className='cust_container'>
                    <div className='row allctctdiv'>
                        <Allimmigrate/>
                        <ImmigrationList />
                    </div>

                    <div className=''>
                        <h4 className='hwtxtbcdiv'>How to become a Canadian citizen?</h4>
                        <ImmigrationDesc  />
                    </div>

                    
                </div>
                <ImmigrationForm anstr="If you are interested in becoming a Canadian citizen, you can start by checking your eligibility and connect with Desh Videsh Immigration Team." text="Becoming a Canadian citizen is a rewarding and meaningful step in your immigration journey. By joining the Canadian family, you can enjoy the rights and responsibilities that come with citizenship, and contribute to the diversity and prosperity of this great country."/>

            </section>
        </>
    )
}

export default Citizenship
