import React from 'react'

const Limabenefits = () => {
  return (
    <>
        <section className='exprssbenfits'>
        <div className='exprsgg'>
            <h4 className='cndatrpp algn'> What are the benefits of the LMIA program? </h4>
            <p className='ptxtcppas'>The LMIA program has many benefits for Canadian employers and foreign workers, such as:</p>
            <div>
            <ul className='ullistpp'>
                        <li className='othlstdipp'>Filling labour gaps and skills shortages. The LMIA program helps Canadian employers find qualified and experienced workers for jobs that are in high demand or hard to fill by Canadians. The LMIA program also helps foreign workers find employment opportunities and gain valuable work experience in Canada.</li>
                        <li className='othlstdipp'>Supporting economic growth and development. The LMIA program supports the Canadian economy by creating jobs, increasing productivity, enhancing competitiveness, and fostering innovation. The LMIA program also supports the social and cultural diversity of Canada by attracting foreign workers from different backgrounds and perspectives.</li>
                        <li className='othlstdipp'>Offering flexibility and choice. The LMIA program offers different streams and categories that cater to the specific needs and situations of employers and workers. The LMIA program also allows employers and workers to choose the duration and location of the employment contract, depending on the availability and demand of the job.</li>
                    </ul>
            </div>
        </div>
       </section>
    </>
  )
}

export default Limabenefits
