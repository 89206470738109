import React from "react";
import "../../View/BookAppoinment/BookAppointment.css";
import samplelogo from "../../Image/samplelogo.jpg";
import deshvideshimmigration from "../../Image/deshvideshimmigration.png"
function BookAppImmi() {
  return (
    <>
      <p className="bookAppHead">Book an Appointment</p>
      <div className="immiAllDivsMain">
      <div className="lghdiv">
              <figure Link to="/" className="logo_hd_mn">
                <img src={deshvideshimmigration} alt="..." />
                <p className='deshtxt'>DeshVidesh Immigration</p>
              </figure>
            </div>
        {/* <div className="immiMainDiv">
          <figure className="immiLogoFig">
            <img src={samplelogo} alt="..." />
          </figure>
          <p className="immiHead">Deshvidesh IMMIGRATION</p>
        </div> */}
        {/* <button className="immiSignBtn">Sign in</button> */}
      </div>
    </>
  );
}

export default BookAppImmi;
