import React from 'react'
import { Link } from 'react-router-dom'

const Permanent = () => {
    return (
        <>
            <div className='col-lg-8 col-xl-8 col-md-6 col-12'>
                <div className=''>
                    <h4 className='cndntxtpp'>What is permanent residence in Canada?</h4>
                    <p className='cndtxtdiv'>Permanent residence is a legal status that allows you to live, work, and study in Canada indefinitely, as long as you meet the residency obligation and other requirements. Permanent residents are not Canadian citizens, but they enjoy many benefits and rights that citizens have, such as access to health care, education, and social services. Permanent residents can also apply for Canadian citizenship after meeting certain criteria.</p>
                </div>
                <div className=''>
                    <h4 className='cndntxtppcntpp'>How to apply for permanent residence in Canada?</h4>
                    <p className='wystxtpp'>There are different ways to apply for permanent residence in Canada, depending on your situation and eligibility. Some of the most common programs are:</p>
                    <ul className='ulxctctfg'>
                        <li className='exptxtpp'>
                            <span className='spnbvtxt'> Express Entry: </span>
                            This is an online system that manages applications for skilled workers who want to immigrate to Canada. You need to create an online profile and submit information about your skills, education, work experience, language ability, and other factors. You will be given a score based on the Comprehensive Ranking System (CRS) and placed in a pool of candidates. If you meet the minimum score and other criteria, you may receive an invitation to apply for permanent residence. You will have 60 days to submit your complete application online. <Link to="/">
                                Learn more about Express Entry.</Link> </li>
                        <li className='exptxtpp'>
                            <span className='spnbvtxt'> Provincial Nominee Program (PNP): </span>
                            This is a program that allows provinces and territories to nominate immigrants who have the skills, education, and work experience to contribute to their economy and society. You need to apply to the province or territory where you want to live and be nominated by them. Then, you need to apply to Immigration, Refugees and Citizenship Canada (IRCC) for permanent residence. Some provinces and territories have streams that are aligned with Express Entry, which means you can apply through both systems. <Link to="/" >Learn more about PNP.</Link>
                        </li>
                        <li className='exptxtpp'> <span className='spnbvtxt'> Family sponsorship:  </span> This is a program that allows Canadian citizens and permanent residents to sponsor their eligible relatives to come to Canada as permanent residents. You need to be at least 18 years old and have enough income to support your sponsored family members. You also need to sign an undertaking to provide for their basic needs for a certain period of time. You can sponsor your spouse, partner, children, parents, grandparents, and other relatives under certain conditions.
                            <Link to="/">Learn more about family sponsorship.</Link> </li>
                        <li className='exptxtpp'> <span className='spnbvtxt'> Economic Mobility Pathways Pilot (EMPP):  </span>This is a pilot program that allows foreign nationals who are refugees or displaced persons to apply for permanent residence through economic immigration programs. You need to have the skills, education, and work experience that match the requirements of the program you are applying for. You also need to have a valid job offer from a Canadian employer and meet the language and settlement requirements. <Link to="/"> Learn more about EMPP.</Link> </li>
                    </ul>
                </div>
            </div>

        </>
    )
}

export default Permanent
