import React, { useEffect, useState } from "react";
import "../About/About.css";
import InnerBanner from "../../Component/InnerBanner";
// import OurTeam from "./OurTeam";
import MissonVisson from "./MissonVisson";
import AboutText from "./AboutText";
import badgelineyellow from "../../Image/badgelineyellow.svg"
import abtvct from "../../Image/abtvct.png";
import abtvctup from "../../Image/abtvctup.png";
import srvc2 from "../../Image/srvc2.jpg";
import CustomLoader from "../../loader/CustomLoader";

function Index() {
const [isLoading, setLoading] = useState(true);
useEffect(() => {
  setTimeout(() => {
    setLoading(false);
  }, 1500);
}, []);
  return (
    <>
      <div className="main">
    <CustomLoader loading={isLoading} />
        
        <InnerBanner InBanHead="About Us" InBanLi="About" />


        <section className="about_main_section mb-5">
        <div className="cust_container">
          <div className="mainastltxtdiv">
            <p className="abt_div_txt">About Us</p>
            <div className="divimgavg">
              <img src={badgelineyellow} alt="..." />
            </div>
          </div>
          <div className="aboutmaindivflx">
            <div className="about_txt_dtls_div">
              <div className="abt_main_txt">
                {/* <h4>
                  We Create a Culture That Inspires Us To Work Smart Together
                </h4> */}
                <p>
                "Welcome to Desh Videsh Immigration, your trusted gateway to a world of opportunities. Founded by <span className="textsjt">Sujeet Dahiya</span>, a distinguished member of the <span className="textsjt">College of Immigration and Citizenship Consultants (CICC)</span>, we are an Ontario-based immigration firm dedicated to turning dreams into reality.
                </p>
                <p>At Desh Videsh, we understand that the journey towards a new beginning in a different land can be both thrilling and challenging. With a wealth of experience and a commitment to excellence, our team stands ready to guide you through the intricate pathways of immigration and citizenship.</p>
                <p>Our mission is simple yet profound: to offer personalized, ethical, and comprehensive immigration solutions tailored to your unique needs. We believe in fostering trust, transparency, and integrity in every interaction, ensuring that you feel empowered and supported at every step.</p>
                <p>As a beacon of reliability and professionalism in the immigration landscape, we take immense pride in assisting individuals and families in making Canada their cherished new home. Your dreams matter to us, and we are honored to be a part of your journey.</p>
                <p>Contact us today to schedule a consultation and take the first step towards a brighter future with Desh Videsh Immigration. Let's navigate your path to new horizons together."</p>
              </div>

              
            </div>

            <div className="about_img_dtls_div">
              <div className="abtallimgdiv d-flex align-content-center justify-content-center align-items-center">
                <div className="abtvctdivimg">
                  <img src={abtvct} alt="..." />
                </div>
                <div
                  class="expert-team expert-team-one text-center"
                  style={{ backgroundImage: `url(${abtvctup})` }}
                >
                  <div class="number color-white mb-10 mb-xs-5 fw-600">
                    <span></span>
                  </div>
                  <h6 class="title font-la color-white">Expert Team Members</h6>
                </div>
                <div
                  class="expert-team expert-team-two text-center"
                  style={{ backgroundImage: `url(${srvc2})` }}
                >
                  <div class="number color-white mb-10 mb-xs-5 fw-600">
                    <span>500</span>+
                  </div>
                  <h6 class="title font-la color-white">
                    Clients Satisfaction Survey In Consulting Organization
                  </h6>
                </div>
                <div
                  class="expert-team expert-team-three text-center"
                  style={{ backgroundImage: `url(${srvc2})` }}
                >
                  <div class="number color-white mb-10 mb-xs-5 fw-600">
                    <span>10</span>+
                  </div>
                  <h6 class="title font-la color-white">
                    Years Experience Our Company
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        {/* <AboutText /> */}

        {/* <MissonVisson />  */}

        {/* <OurTeam /> */}

      </div>
    </>
  );
}

export default Index;
