import React, { useState, useContext, useEffect } from "react";
import "./BookAppointment.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PhmApt from "../../Component/BookAppointment/PhmApt";
import BookAppImmi from "../../Component/BookAppointment/BookAppImmi";
import { Link, useNavigate } from "react-router-dom";
import SelectTimeZone from "../../Component/Modal/SelectTimeZone";
import { Colorcontext } from "../../Component/context/ColorContext";
import HomeServices from "../../Services/HomeServices";

function BookAppointment() {
  const colordata = useContext(Colorcontext);
  const phnSelect = [
    {
      phnAppT: "Phone Appointment (30 Minutes) One",
      money: "$35.00",
      time: "30 minutes",
    },
    {
      phnAppT: "Phone Appointment (30 Minutes) Two",
      money: "$100.00",
      time: "30 minutes",
    },
    {
      phnAppT: "Phone Appointment (30 Minutes) Three",
      money: "$50.00",
      time: "30 minutes",
    },
  ];

  const inPersonSelect = [
    {
      phnAppT: "BRAMPTON - In-Person Appt",
      money: "$50.00",
      time: "30 minutes",
    },
    {
      phnAppT: "KITCHENER - In- Person Appt",
      money: "$50.00",
      time: "30 minutes",
    },
  ];

  const [modalA, setModalA] = useState(false);
  const timeZoneModalOpen = (value) => {
    console.log("vnbhdyru1111", value);
    localStorage.setItem("formData", JSON.stringify(value));
    setModalA(true);
    if (value?.isUrgent === true) {
      navigate("/urgent-preferdateTime", { state: value });
    } else {
      navigate("/preferdateTime", { state: value });
    }
  };

  const [serType, setServType] = useState("");
  const [serType1, setServType1] = useState("");
  const navigate = useNavigate();

  const [serviceType, setServiceType] = useState([]);
  console.log("serviceType", serviceType[0]);

  useEffect(() => {
    colordata.setcolor(true);

    setServType("phone");
    handleClickTab("phone");
    return () => {
      colordata.setcolor(false);
    };
  }, []);

  const handleClickTab = async (tab) => {
    setServType(tab);
    let data = {
      service_type: tab,
    };
    const res = await HomeServices.bookAppointment(data);
    console.log("xbcvjdgfuey78326", res?.data);
    setServiceType(res?.data);
  };

  const handleClickTabs = async (tab) => {
    setServType1(tab);
    let data = {
      service_type: tab,
    };
    const res = await HomeServices.bookAppointment(data);
    console.log("resNAme", res?.data);
    setServiceType(res?.data);
  };

  return (
    <>
      <section className="bookAppoSection">
        <div className="cust_container">
          <BookAppImmi />
          <div className="row">
            <div className="col-12 mt-3 mt-md-0">
              <p className="seleSerText">Select a service</p>
              <div className="slectAftTab">
                <Tabs>
                  <div className="tablistDiv">
                    <TabList>
                      {/* phone appoinment */}
                      <Tab onClick={() => handleClickTab("phone")}>Phone</Tab>
                      {/* inperson appoitment */}
                      <Tab onClick={() => handleClickTabs("in_person_office")}>
                        In Person - Office
                      </Tab>
                    </TabList>
                  </div>

                  {serType === "phone" && (
                    <TabPanel>
                      <div className="slecTabPanDiv">
                        <p className="slecHead">PHONE</p>
                        <div>
                          {serviceType?.map((val, index, arr) => {
                            console.log("213zxv654", val);
                            return (
                              <div
                                className="phmAptLink"
                                style={{ marginBottom: "25px" }}
                                onClick={() => timeZoneModalOpen(val)}
                              >
                                <PhmApt
                                  phnText={`${val?.name} (${val?.timing})`}
                                  money={`$${val?.price}`}
                                  duration={val?.timing}
                                  urgent={
                                    val?.isUrgent === true ? "( Urgent )" : ""
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </TabPanel>
                  )}

                  {serType1 === "in_person_office" && (
                    <TabPanel>
                      <div className="slecTabPanDiv">
                        <p className="slecHead">IN PERSON- Office</p>
                        <div>
                          {serviceType.map((val, index, arr) => {
                            return (
                              <div
                                className="phmAptLink"
                                onClick={() => timeZoneModalOpen(val)}
                              >
                                <PhmApt
                                  phnText={`${val?.name} (${val?.timing})`}
                                  money={`$${val?.price}`}
                                  duration={val?.timing}
                                  urgent={
                                    val?.isUrgent === true ? "( Urgent )" : ""
                                  }
                                  margBtn={index === arr.length - 1 ? 0 : 25}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </TabPanel>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      {modalA && <SelectTimeZone closeTimeZoneModal={setModalA} />}
    </>
  );
}

export default BookAppointment;
