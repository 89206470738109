import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import citizenship from "../../Image/citizenship.png";
import ExpressEntry from "../../Image/ExpressEntry.png"
import srvc2 from "../../Image/srvc2.jpg";
import srvc3 from "../../Image/srvc3.jpg";
import abtvct from "../../Image/abtvct.png";
import abtvctup from "../../Image/abtvctup.png";
import permanentResident from "../../Image/permanentResident.png";
import LMIA from "../../Image/LMIA.png";
import workPermit from "../../Image/workPermit.png";
import studentVisa from "../../Image/studentVisa.png"
import { Link } from "react-router-dom";
// import blg1 from "../../Image/blg1.png";
// import blg2 from "../../Image/blg2.png";
// import blg3 from "../../Image/blg2.png";
import badgelineyellow from "../../Image/badgelineyellow.svg"
import testimonial1 from "../../Image/testimonial1.png";
import testimonial2 from "../../Image/testimonial2.png";
import testimonial3 from "../../Image/testimonial3.png";
import testimonial4 from "../../Image/testimonial4.png";
import testimonial5 from "../../Image/testimonial5.png";
import "../../View/Home/Home.css";
// import BlogCard from "../../Component/BlogCard";
// import BlogMain from "../../Component/Blog/BlogMain";
// import HomeService from "../../Component/Home/HomeService";
import BlogMain from "../../Component/Blog/BlogMain";
import Bannerslide from "../../Component/Home/Bannerslide";
import CustomLoader from "../../loader/CustomLoader";
// import { Colorcontext } from "../../Component/context/ColorContext";
// import AOS from "aos";

// import "aos/dist/aos.css";
const Home = () => {

// const [isLoading, setIsLoading] = useState(false)
const [isLoading, setLoading] = useState(true);



  const service = [
    {
      // image: citizenship,
      icon: <i className="fa-solid fa-users"></i>,
      text: "Citizenship",
      desc: "Canada is a country that welcomes immigrants from all over the world. As a permanent resident, you already enjoy many benefits of living in Canada, such as access to health care, education, and social services.",
      Link: "/citizenship"
    },
    {
      // image: permanentResident,
      icon: <i className="fa-solid fa-id-card"></i>,
      text: "Permanent Residence",
      desc: "Permanent residence is a legal status that allows you to live, work, and study in Canada indefinitely, as long as you meet the residency obligation and other requirements. Permanent residents are not Canadian citizens, but they enjoy many benefits and rights that citizens have,  ",
      Link: "/PermanentResidence"
    },
    {
      // image: ExpressEntry,
      icon: <i className="fa-solid fa-people-group"></i>,
      text: "Express Entry",
      desc: "Express Entry is an online system that manages applications for permanent residence in Canada from skilled workers who want to settle in Canada permanently and take part in its economy. Express Entry is the fastest and most popular immigration program under Canadian immigration1.",
      Link: "/ExpressEntry"
    },
    {
      // image: LMIA,
      icon: <i className="fa-solid fa-briefcase"></i>,
      text: "LMIA",
      desc: "The LMIA program is a part of the Temporary Foreign Worker Program (TFWP) that allows Canadian employers to hire foreign workers for jobs that cannot be filled by Canadian citizens or permanent residents. LMIA stands for Labour Market Impact Assessment,  ",
      Link: "/LMIA"
    },
    {

      // image: workPermit,
      icon: <i className="fa-solid fa-briefcase"></i>,
      text: "Work Permit",
      desc: "Work permit programs in Canada are immigration programs that allow foreign nationals to live and work in Canada temporarily or permanently, depending on the type of program and the eligibility criteria. ",
      Link: "/workpermit"
    },
    {
      // image: studentVisa,
      icon: <i className="fa-solid fa-passport"></i>,
      text: "Student Visa",
      desc: "Canada is one of the most popular destinations for international students who want to pursue higher education in a diverse and multicultural country. Canada offers a high quality of education, a welcoming environment, and a high standard of living.",
      Link: "/studentVisa"
    },
  ];

  var testimonial = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 730,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const testimonialbox = [
    {
      image: testimonial2,
      name: "Jatin Sharma ",
      date: "20th Nov,2023",
      text: "I have chosen Desh Videsh because my friend suggested me about Study visa services of this company. I connected with them on phone and liked the way by which they handled my queries. I decided to go ahead with Sujeet Sir. I got my study visa within 2 weeks. Thank You Team Desh Videsh immigration.",
    },
    {
      image: testimonial3,
      name: "Puneet Kaur ",
      date: "25th Nov,2023",
      text: "I am Puneet Kaur and currently I am a permanent resident in Canada and my entire process was taken care by Desh Videsh Immigration. I really appreciate all the hard work done by them all the efforts taken by them so my message to them is just that keep up good work I really appreciate all the hard work done by you. Best Immigration Consultant and genuine prices for services, saved lot of money.",
    },
    {
      image: testimonial4,
      name: "Abrahim",
      date: "30th Nov,2023",
      text: "Me and my friend approached many consultancies in Brampton. Eventually we came to know about Desh Videsh immigration. They guided and motivated in our visa processing. We got our study permit extension in few weeks. We are very thankful to Team Desh Videsh and Sujeet Sir. I strongly recommend this consultancy; their services were spectacular. Genuine prices and cooperative.",
    },
    {
      image: testimonial5,
      name: "Gurdeep Malik",
      date: "2 dec,2023",
      text: "Hi, My Wife is studying in Canada and I approached many immigration consultancies in India (Delhi and Chandigarh) and even got one rejection from Chandigarh reputed consultancy, then I contacted Sujeet Dahiya through social media (Desh Videsh Immigration) and they helped me through the whole process, I got my Open Work Permitprocessed in a short term. Very professional and timely service in order to get the process completed. Trust worthy consultant. Had a great experience with Desh Videsh Immigration. Very polite and genuine, if you want to save your time and money. Contact them.",
    },
    {
      image: testimonial4,
      name: "Abrahim",
      date: "8 dec,2023",
      text: "Me and my friend approached many consultancies in Brampton. Eventually we came to know about Desh Videsh immigration. They guided and motivated in our visa processing. We got our study permit extension in few weeks. We are very thankful to Team Desh Videsh and Sujeet Sir. I strongly recommend this consultancy; their services were spectacular. Genuine prices and cooperative.",
    },
    {
      image: testimonial3,
      name: "Puneet Kaur ",
      date: "10th dec,2023",
      text: "I am Rajni Sharma and currently I am a permanent resident in Canada and my entire process was taken care by Desh Videsh Immigration. I really appreciate all the hard work done by them all the efforts taken by them so my message to them is just that keep up good work I really appreciate all the hard work done by you. Best Immigration Consultant and genuine prices for services, saved lot of money.",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <>
    <CustomLoader loading={isLoading} />

      <Bannerslide />
      <section className="srvc_main_section">
        <div className="cust_container">
          <div className="mainastltxtdiv">
            <p className="abt_div_txt">Immigration Programs</p>
            <div className="divimgavg">
              <img src={badgelineyellow} alt="..." />
            </div>
          </div>
          <div className="srvc_sct_bg">
            {service.map((item, index) => {
              return (
                <div className="srvc_box_main">
                  <div className="srvcndiv">
                    <h4 className="srvctxtppp">{item.text}</h4>
                  </div> 
                  <div className="iccn">
                    {item.icon}
                  </div>
                  <div className="srvcd_txtr">

                    <p>{item.desc}</p>
                  </div>
                  <div className="mre_btn_divv">
                    <Link to={item.Link} className="mre_btn">
                      More Details
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="about_main_section">
        <div className="cust_container">
          <div className="mainastltxtdiv">
            <p className="abt_div_txt">About Us</p>
            <div className="divimgavg">
              <img src={badgelineyellow} alt="..." />
            </div>
          </div>
          <div className="aboutmaindivflx">
            <div className="about_txt_dtls_div">
              <div className="abt_main_txt"> 
                <p>
                "Welcome to Desh Videsh Immigration, your trusted gateway to a world of opportunities. Founded by <span className="textsjt">Sujeet Dahiya</span>, a distinguished member of the <span className="textsjt">College of Immigration and Citizenship Consultants (CICC)</span>, we are an Ontario-based immigration firm dedicated to turning dreams into reality.
                </p>
                <p>At Desh Videsh, we understand that the journey towards a new beginning in a different land can be both thrilling and challenging. With a wealth of experience and a commitment to excellence, our team stands ready to guide you through the intricate pathways of immigration and citizenship.</p>
                <p>Our mission is simple yet profound: to offer personalized, ethical, and comprehensive immigration solutions tailored to your unique needs. We believe in fostering trust, transparency, and integrity in every interaction, ensuring that you feel empowered and supported at every step.</p>
                <p>As a beacon of reliability and professionalism in the immigration landscape, we take immense pride in assisting individuals and families in making Canada their cherished new home. Your dreams matter to us, and we are honored to be a part of your journey.</p>
                <p>Contact us today to schedule a consultation and take the first step towards a brighter future with Desh Videsh Immigration. Let's navigate your path to new horizons together."</p>
              </div> 
            </div>

            <div className="about_img_dtls_div">
              <div className="abtallimgdiv d-flex align-content-center justify-content-center align-items-center">
                <div className="abtvctdivimg">
                  <img src={abtvct} alt="..." />
                </div>
                <div
                  class="expert-team expert-team-one text-center"
                  style={{ backgroundImage: `url(${abtvctup})` }}
                >
                  <div class="number color-white mb-10 mb-xs-5 fw-600">
                    <span></span>
                  </div>
                  <h6 class="title font-la color-white">Expert Team Members</h6>
                </div>
                <div
                  class="expert-team expert-team-two text-center"
                  style={{ backgroundImage: `url(${srvc2})` }}
                >
                  <div class="number color-white mb-10 mb-xs-5 fw-600">
                    <span>500</span>+
                  </div>
                  <h6 class="title font-la color-white">
                    Clients Satisfaction Survey In Consulting Organization
                  </h6>
                </div>
                <div
                  class="expert-team expert-team-three text-center"
                  style={{ backgroundImage: `url(${srvc2})` }}
                >
                  <div class="number color-white mb-10 mb-xs-5 fw-600">
                    <span>10</span>+
                  </div>
                  <h6 class="title font-la color-white">
                    Years Experience Our Company
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog_main_section">
        <div className="cust_container">
          <div className="mainastltxtdiv">
            <p className="abt_div_txt">Our Blog</p>
            <div className="divimgavg">
              <img src={badgelineyellow} alt="..." />
            </div>
          </div>
          <div className="blg_txthhh">
            <h4>Consulter Latest Blog & News</h4>
            <div className="seedivblg">
              <Link to="/blog" className="seebtn">
                SEE ALL BLOG
              </Link>
            </div>
          </div>

          <div className="blgmaindivflx">
            <BlogMain />
          </div>
        </div>
      </section>

      <section className="testimonial_main_section">
        <div className="cust_container">
          <div className="mainastltxtdiv">
            <p className="abt_div_txt">Our Testimonial</p>
            <div className="divimgavg">
              <img src={badgelineyellow} alt="..." />
            </div>
          </div>
          <div className="tstsdivmain">
            <Slider {...testimonial}>
              {testimonialbox.map((item, index) => {
                return (
                  <div className="tstboxdiv" key={index}>
                    <div className="tstimgtxt">
                      <div className="tst_img">
                        <img src={item.image} alt="..." />
                      </div>
                      <div className="txtdiv">
                        <p className="tsttxtdivpp">{item.name}</p>
                        <p className="tsttxtdivppdt">{item.date}</p>
                        <div className="tsetrating">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <p className="tsttxtdivhh">{item.text}</p>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;