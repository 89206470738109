import React from 'react'

const SpousalSponsorshiplist = () => {
    return (
        <>
            <div className='col-lg-8 col-xl-8 col-md-6 col-12'>
                <div className=''>
                    <h4 className='cndntxtpp'>What is spousal sponsorship in Canada?</h4>
                    <p className='cndtxtdiv'>Spousal sponsorship in Canada is a program that allows Canadian citizens and permanent residents to sponsor their foreign spouse, partner, or child to become permanent residents of Canada. Spousal sponsorship in Canada is a part of the family sponsorship category, which is one of the main ways to immigrate to Canada.</p>
                </div>
                <div className=''>
                    <h4 className='cndntxtppcntpp'>Who can apply for spousal sponsorship in Canada?</h4>
                    <p className='wystxtpp'>To apply for spousal sponsorship in Canada, you must meet the following requirements:</p>
                    <ul className='ulxctctfg'>
                        <li className='exptxtpp'>
                            You must be a Canadian citizen or permanent resident who is at least 18 years old</li>
                        <li className='exptxtpp'>
                            You must have enough income to support your sponsored family members and yourself, and sign an undertaking to provide for their basic needs for a certain period of time
                        </li>
                        <li className='exptxtpp'>
                            You must not have a criminal record, a serious medical condition, or a previous sponsorship debt or default
                        </li>
                        <li className='exptxtpp'>
                            You must not be in prison, bankrupt, or under a removal order</li>
                    </ul>
                </div>
                <div className=''>
                    <p className='txtghprnst'>You can sponsor your spouse, partner, or child if they meet the following requirements:</p>
                    <ul className='cbngfhyt'>
                        <li className='istxyvv'>Your spouse is legally married to you and is at least 18 years old</li>
                        <li className='istxyvv'>Your partner is either your common-law partner or your conjugal partner, and is at least 18 years old</li>
                        <ul className='ulendfgg'>
                            <li className='gghbhtxt'>
                                Your common-law partner is someone who has lived with you in a conjugal relationship for at least one year
                            </li>
                            <li  className='gghbhtxt'>
                                Your conjugal partner is someone who has a committed and exclusive relationship with you, but cannot live with you due to exceptional circumstances, such as immigration barriers, religious reasons, or sexual orientation
                            </li>
                        </ul>
                        <li  className='istxyvv'> Your child is either your biological child or your adopted child, and is under 22 years old and unmarried, or is over 22 years old and depends on you financially due to a physical or mental condition.</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SpousalSponsorshiplist
