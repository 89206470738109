import React from 'react'
import { Link } from 'react-router-dom'
import requestform from "../../Image/requestform.jpg"
const ImmigrationForm = ({ text, anstr }) => {
    return (
        <>
            <div className='bgimgdiv' style={{ backgroundImage: `url(${requestform})` }}>
                <div className='cust_container'>
                    <h4 className='txtppp'>Ready To Apply</h4>
                    <div className='rdyapplyflx'>
                        <div className='divtxtflx'>
                            <p className='divtxtpppifd'>{anstr}</p>
                            <p className='divcttxtpp'>{text}</p>
                        </div>

                        <div className='frmdiv'>
                            <form>
                                <div className="row">
                                    <div className="col-6 pb-3 frstclss ">
                                        <input type="text" className="form-control" placeholder="First name" />
                                    </div>
                                    <div className="col-6 pb-3 frstclss">
                                        <input type="text" className="form-control" placeholder="Last name" />
                                    </div>

                                    <div className="col-6 pb-3 frstclss">
                                        <input type="Ph NO." class="form-control" placeholder="Ph NO." />
                                    </div>
                                    <div class="col-6 pb-3 frstclss">
                                        <input type="text" class="form-control" placeholder="City" />
                                    </div>
                                    <div className='col-12 pb-3 frstclss'>
                                        <input type="email" class="form-control" placeholder="Email" />
                                    </div>
                                    <div className='col-12 txtareclss'>
                                        <textarea id="w3review" name="w3review" rows="4" cols="50" placeholder=""></textarea>
                                    </div>
                                    <div className='sndbtnmain'>
                                        <Link className="sndbthn">Send</Link>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ImmigrationForm
