import React, { useEffect, useState } from "react";
import "./Blog.css";
import InnerBanner from "../../Component/InnerBanner";
import blogDetailImg from "../../Image/blogDetailImg.jpg";
import bsg from "../../Image/blogSmallImg.jpg";
import blogSmallImg2 from "../../Image/blogSmallImg2.jpg";
import cmntImg from "../../Image/cmntImg.jpg";
import cmntImg2 from "../../Image/cmntImg2.jpg";
import { Link, useLocation, useParams } from "react-router-dom";
import BlogComment from "../../Component/Blog/BlogComment";
import recentBlogImg from "../../Image/recentBlogImg.jpg";
import HomeServices from "../../Services/HomeServices";
import { toast } from "react-hot-toast";
import HttpClientXml from "../../utils/HttpClientXml";

function BlogDetails() {
  const location = useLocation();
  const [blogData, setBlogData] = useState();
  const [moreDetail, setMoreDetails] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const params = useParams();
  const [paramID, setparamID] = useState(params?.id);
  console.log("mbncvbj654jhewgjf", moreDetail);
  console.log("dghvahcag5745ajdbfjda", blogData?.data);
  console.log("params", params);
  console.log("paramID", paramID);

  const fetchMoreDetais = async () => {
    const res = await HttpClientXml.requestData("view-blog/" + paramID, "GET");
    if (res && res.status) {
      setMoreDetails(res?.data);
    }

    // setMoreDetails(location?.state);
  };

  const fetchData = async () => {
    try {
      const res = await HomeServices.getBlog();
      if (res && res.status) {
        console.log("getBlogResponse", res);
        setBlogData(res);
      } else {
        // toast.error(res.message);
      }
    } catch {
      toast.error("server error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const blogSmallImg = [
    {
      image: bsg,
    },
    {
      image: blogSmallImg2,
    },
  ];

  const blogAftBtn = ["Business", "Finance", "Solution"];

  const blogSocialDiv = [
    <i class="fa-brands fa-facebook-f"></i>,
    <i class="fa-brands fa-twitter"></i>,
    <i class="fa-brands fa-instagram"></i>,
    <i class="fa-brands fa-linkedin-in"></i>,
  ];

  const cardCmnt = [
    {
      image: cmntImg,
      name: "Md Ashikul Islam",
      month: "January",
      date: 22,
      time: "7:30",
      amPm: "am",
      year: 2022,
    },
    {
      image: cmntImg2,
      name: "Md jhon Islam",
      month: "January",
      date: 22,
      time: "7:30",
      amPm: "pm",
      year: 2023,
    },
  ];

  const resentPost = [
    {
      image: blogDetailImg,
      text: "Saving Time Achieving Success in Business",
      date: "12 FEB, 2022",
    },
    {
      image: recentBlogImg,
      text: "Finances and Accounting are One of the Hardest",
      date: "12 JUN, 2022",
    },
    {
      image: blogDetailImg,
      text: "Saving Time Achieving Success in Business",
      date: "12 MAR, 2022",
    },
    {
      image: recentBlogImg,
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe expedita natus nemo placeat pariatur inventore.",
      date: "12 JAN, 2022",
    },
  ];

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter blogData based on the search query
  const filteredBlogData = blogData?.data?.filter((value) =>
    value?.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  console.log(filteredBlogData, "filteredBlogData");

  const FilterdDataLoad = async (e, id) => {
    window.location.href = `/blogdetails/${id}`;
  };

  useEffect(() => {
    fetchMoreDetais();
  }, [paramID]);

  return (
    <>
      <section className="blogDetailSection">
        <InnerBanner InBanHead="Blog Details" InBanLi="Blog Detail" />
        <div className="cust_container mt-lg-5 mt-4">
          <div className="row">
            <div className="col-xl-8">
              <div>
                <figure className="blogDetailFig">
                  <img src={moreDetail?.image1} alt="..." />
                </figure>
                <div className="blogDetailText">
                  <p className="conslHead">{moreDetail?.title}</p>
                  <div>
                    <p className="dbleQuoteText">
                      <i class="fa-solid fa-quote-left"></i>
                    </p>
                    <p
                      className="consDetailPara"
                      style={{ textAlign: "justify" }}
                      dangerouslySetInnerHTML={{
                        __html: moreDetail?.description,
                      }}
                    ></p>

                    <p className="dbleQuoteText">
                      <i class="fa-solid fa-quote-right"></i>
                    </p>
                  </div>

                  <div className="blogAftSociDiv">
                    <ul className="blogSocialUl">
                      {blogSocialDiv.map((value) => {
                        return (
                          <li className="blogSocialLi">
                            <Link to="/" className="blogSociLink">
                              {value}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/***Recent Post***/}
            <div className="col-xl-4">
              <div>
                <div className="resentPostDiv">
                  <p className="searchHereHead">Recent Post</p>
                  <div className="resentAllBlgDiv">
                    {filteredBlogData &&
                      filteredBlogData
                        ?.slice()
                        ?.reverse()
                        ?.map((value, index) => {
                          console.log("recentBlogPost", value);
                          const date = new Date(value?.createdAt);
                          const month = monthName[date.getMonth()];
                          return (
                            <div
                              className=""
                              onClick={(e) => FilterdDataLoad(e, value._id)}
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                className="resentBlogDiv"
                                style={{
                                  marginBottom: `${
                                    index === resentPost.length - 1 ? 0 : 30
                                  }px`,
                                }}
                              >
                                <figure className="resentBlogFig">
                                  <img src={value?.image1} alt="..." />
                                </figure>
                                <div className="resentOverlayDiv">
                                  <div className="twlMainDiv">
                                    <div className="twlJunText">
                                      <span className="clkSpan">
                                        <i class="fa-regular fa-clock"></i>
                                      </span>
                                      <span>
                                        {new Date(value?.createdAt)
                                          .getDate()
                                          .toString()
                                          .padStart(2, "0")}{" "}
                                      </span>
                                      <span>
                                        {month.slice(0, 3)}
                                        {", "}
                                      </span>
                                      <span>
                                        {new Date(
                                          value?.createdAt
                                        ).getFullYear()}
                                      </span>
                                      {/* <span>{value.date}</span> */}
                                    </div>
                                    <p className="twlAftText">{value?.title}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                  </div>
                  <Link to="/blog" className="morePostBtn">
                    <i class="fa-solid fa-rotate-right"></i> More Post
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogDetails;
