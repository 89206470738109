import React from 'react'
import { Link } from 'react-router-dom'

const Studentlist = () => {
  return (
    <>
     <div className='col-lg-8 col-xl-8 col-md-6 col-12'>
    <div className=''>
        <h4 className='cndntxtpp'>What is Canada Student Visa ?</h4>
        <p className='cndtxtdiv'>Canada is one of the most popular destinations for international students who want to pursue higher education in a diverse and multicultural country. Canada offers a high quality of education, a welcoming environment, and a high standard of living. If you are interested in studying in Canada, you will need to apply for a Canada student visa, also known as a study permit.</p>
        <p className='cndtxtdiv'>A Canada student visa is a document that allows you to enter and stay in Canada for the duration of your study program. You will need a Canada student visa if you want to study in Canada for more than six months. You will also need a Canada student visa if you want to work while studying or after graduating.</p>
    </div>
    <div className=''>
        {/* <h4 className='cndntxtppcntpp'>Who can apply for a Canada visitor visa?</h4> */}
        <p className='wystxtpp'>To apply for a Canada student visa, you will need to meet the following requirements:</p>
        <ul className='ulxctctfg'>
            <li className='exptxtpp'>
            You must have a letter of acceptance from a designated learning institution (DLI) in Canada. A DLI is a school, college, university, or other educational institution that is authorized to host international students. <Link to="/">You can find a list of DLIs on the Government of Canada website.</Link></li>
            <li className='exptxtpp'>
        	You must prove that you have enough money to pay for your tuition fees, living expenses
            </li>
            <li className='exptxtpp'>
            You must prove that you have enough money to pay for your tuition fees, living expenses, and transportation costs for yourself and any family members who come with you to Canada. You may need to provide bank statements, scholarship letters, or other financial documents to show your funds.
            </li>
            <li className='exptxtpp'>
            You must be in good health and get a medical exam (if required). You may need to undergo a medical exam by a panel physician approved by the Government of Canada. <Link to="/">You can find a list of panel physicians on the Government of Canada website.</Link></li>
            <li className='exptxtpp'>
            You must have no criminal record and get a police certificate (if required). You may need to provide a police certificate from your country of origin and any other country where you have lived for more than six months in the past 10 years. <Link to="/">You can find out how to get a police certificate on the Government of Canada website.</Link></li>
            <li className='exptxtpp'>
            	You must convince an immigration officer that you will leave Canada when your study permit expires. You may need to show your ties to your home country, such as family, property, or career prospects.</li>

        </ul>
    </div>
  
</div>
    </>
   
  )
}

export default Studentlist
