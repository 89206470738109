import React from "react";
import "../../View/BookAppoinment/BookAppointment.css";

function PhmApt({ phnText, money, duration, urgent, margBtn }) {
  return (
    <div className="phmAptDiv" style={{ marginBottom: `${margBtn}px` }}>
      <div className="phnAppTextDiv">
        <p className="phnAppText">{phnText}</p>
        <div className="boxHover"></div>
      </div>
      <div>
        <span className="phnAppDoll">{money} .</span>
        <span className="phnAppDoll">{duration} .</span>
        <span className="phnAppDoll" style={{fontWeight:"bold", color:"red"}}>{urgent}</span>
        <button className="phnMoreInfoBtn">More info</button>
      </div>
    </div>
  );
}
// isUrgent
export default PhmApt;
