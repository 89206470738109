import React from "react";
import "./Modal.css";

const SelectTimeZone = ({closeTimeZoneModal}) => {
  const sltTimeArr = [
    "Mountain Time Zone - Currently 12:18 am",
    "Atlantic - Currently 3:18 am",
    "Newfoundland - Currently 3:18 am",
  ];
  return (
    <section className="timeZoneModal" onClick={()=> closeTimeZoneModal()}>
      <div className="innerTimeZoneModal" onClick={(e)=>e.stopPropagation()}>
        <p className="sltTimeHead">
          Select a time zone to display available times
        </p>
        <div className="selectInpZoneDiv">
          <select name="" id="" className="selectInpZone">
            <option value="" disabled selected>
              Select
            </option>
            {sltTimeArr.map((val) => {
              return <option value="">{val}</option>;
            })}
          </select>
        </div>
        <button className="doneBtnTIme" onClick={()=> closeTimeZoneModal()}>Done</button>
      </div>
    </section>
  );
};

export default SelectTimeZone;
