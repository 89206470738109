import React from "react";
import "./BookAppointment.css";
import BackPageBtn from "./BackPageBtn";
import deshvideshimmigration from "../../Image/deshvideshimmigration.png";
import { Link } from "react-router-dom";

const CancelPolicy = () => {
  return (
    <>
      <section className="bookAppoSection">
        <div className="cust_container">
          <p className="bookAppHead">Our Cancellation Policy</p>
          <div className="immiAllDivsMain">
            <div className="lghdiv">
              <figure Link to="/" className="logo_hd_mn">
                <img src={deshvideshimmigration} alt="..." />
                <p className="deshtxt">DeshVidesh Immigration</p>
              </figure>
            </div>
          </div>

          <div>
            <h4>Cancellation Policy –</h4>

            <div className="mt-4">
              {/* <span style={{ fontWeight: "bold" }}>7. Acknowledgment</span>{" "} */}
              <br />
              <p className="mx-3 mt-2" style={{textAlign:"justify", fontSize:"20px"}}>
                Please note that once you have booked an appointment with us it
                means that we have reserved time in our schedule exclusively for
                you. If you cancel your appointment less than 24 hours before it
                is scheduled to take place, you will be subject to a 100%
                Charges of your appointment booked. There are no charges for
                rescheduling before 24hrs. To avoid a cancellation fee, please
                provide cancellation notice at least 24 hours prior to your
                appointment. You can cancel or reschedule an appointment by
                emailing us at <Link>support@deshvidesh.ca</Link>, or calling our office at
                +1 (519) 998-1002.
              </p>
            </div>

            {/* <div className="mt-4">
              <button className="joinWtListBtn">Cancel</button>
            </div> */}

            <BackPageBtn backLink="/bookappointment" />
          </div>
        </div>
      </section>
    </>
  );
};

export default CancelPolicy;
