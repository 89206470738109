import React from 'react'
import { Link } from 'react-router-dom'

const Workimmigrate = () => {
    return (
        <div className='col-lg-8 col-xl-8 col-md-6 col-12'>
            <div className=''>
                <h4 className='cndntxtpp'>What are work permit programs in Canada?</h4>
                <p className='cndtxtdiv'>Work permit programs in Canada are immigration programs that allow foreign nationals to live and work in Canada temporarily or permanently, depending on the type of program and the eligibility criteria. Work permit programs in Canada are designed to meet the needs and interests of the Canadian labour market, economy, and society, as well as to attract skilled and experienced workers from around the world.</p>
            </div>
            <div className=''>
                <h4 className='cndntxtppcntpp'>What are the types of work permit programs in Canada?</h4>
                {/* <p className='wystxtpp'>To apply for the LMIA program, an employer must follow these steps:</p> */}
                <ul className='ulxctctfg'>
                    <li className='exptxtpp'>
                        <span className='spnbvtxt'> Temporary work permit programs: </span>
                        These are programs that allow foreign nationals to work in Canada for a limited period of time, usually up to four years. Temporary work permit programs are divided into two categories: the Temporary Foreign Worker Program (TFWP) and the International Mobility Program (IMP). The TFWP is meant to help fill gaps and shortages in Canada’s labour market with skilled foreign workers. The IMP is meant to promote Canada’s economic, social, and cultural interests by allowing certain types of foreign nationals to work in Canada to strengthen ties with other countries and promote arts, culture, sports, and religion in Canada. </li>
                    <li className='exptxtpp'>
                        <span className='spnbvtxt'> High-wage and low-wage streams : </span>
                        These are streams under the TFWP that allow employers to hire foreign workers for jobs that pay above or below the median hourly wage in the province or territory where the job is located. Employers need to obtain a Labour Market Impact Assessment (LMIA) before hiring a foreign worker, which is a document that shows that there is a need for a foreign worker and that no Canadian worker is available to do the job.
                    </li>
                    <li className='exptxtpp'> <span className='spnbvtxt'> Agricultural stream: </span>
                        This is a stream under the TFWP that allows employers to hire foreign workers for agricultural occupations, such as farm workers, nursery workers, and greenhouse workers. Employers need to obtain an LMIA before hiring a foreign worker, and the foreign worker must meet certain requirements, such as having relevant experience and being able to communicate in English or French.
                    </li>
                    <li className='exptxtpp'> <span className='spnbvtxt'> Caregiver stream: </span>
                        This is a stream under the TFWP that allows employers to hire foreign workers for in-home caregiving occupations, such as child care, elder care, or care for people with disabilities. Employers need to obtain an LMIA before hiring a foreign worker, and the foreign worker must meet certain requirements, such as having relevant education and experience, and being able to communicate in English or French.
                    </li>
                    <li className='exptxtpp'> <span className='spnbvtxt'> Seasonal Agricultural Worker Program (SAWP):  </span>
                        This is a program under the TFWP that allows employers to hire foreign workers from certain countries for seasonal agricultural work, such as planting, harvesting, and packing crops. Employers need to obtain an LMIA before hiring a foreign worker, and the foreign worker must meet certain requirements, such as having relevant experience and being able to communicate in English, French, or Spanish.
                    </li>
                    <li className='exptxtpp'> <span className='spnbvtxt'> International Experience Canada (IEC):  </span>
                        This is a program under the IMP that allows young people from certain countries to work and travel in Canada for up to two years. The IEC has three categories: Working Holiday, Young Professionals, and International Co-op. The IEC does not require an LMIA, but the foreign national must meet certain requirements, such as being between 18 and 35 years old, having a valid passport, and having enough money to support themselves in Canada.
                    </li>
                    <li className='exptxtpp'> <span className='spnbvtxt'> Business visitors:  </span>
                        These are foreign nationals who come to Canada for business purposes, such as attending meetings, conferences, or trade shows, but who do not enter the Canadian labour market. Business visitors do not need a work permit or an LMIA, but they must meet certain requirements, such as having a valid passport, having a letter of invitation from a Canadian business, and having enough money to support themselves in Canada.
                    </li>
                    <li className='exptxtpp'> <span className='spnbvtxt'> Intra-company transferees: </span>
                        These are foreign nationals who are transferred to Canada by their employer to work for a branch, subsidiary, or affiliate of the same company. Intra-company transferees do not need an LMIA, but they must meet certain requirements, such as having a valid passport, having at least one year of full-time work experience with the employer, and working in a managerial, executive, or specialized knowledge position.</li>

                    <li className='exptxtpp'> <span className='spnbvtxt'>Francophone Mobility:   </span>
                        This is a program under the IMP that allows employers to hire French-speaking foreign workers for jobs outside Quebec, to support the development and vitality of Francophone communities in Canada. Employers do not need an LMIA, but they must meet certain requirements, such as offering a job that is at skill level 0, A, or B of the National Occupational Classification (NOC), and demonstrating their efforts to hire Canadians first.</li>
                </ul>
            </div>
        </div>
    )
}

export default Workimmigrate
