import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import deshvideshimmigration from "../Image/deshvideshimmigration.png";
import abtvct from "../../src/Image/abtvct.png";
import ScrollTop from "../Component/ScrollTop";
import HomeServices from "../Services/HomeServices";
import toast from "react-hot-toast";

const Footer = () => {
  const emailField = {
    email: "",
  };
  const [userEmail, setUserEmail] = useState(emailField);

  const handleEmail = (e) => {
    e.preventDefault();
    setUserEmail({ ...userEmail, [e.target.name]: e.target.value });
  };

  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const onSubmit = async (e) => {
    e.preventDefault(); 
    const { email } = userEmail;
    const isValid = email.trim() !== "";
    if (isValid) { 
      if (!email.match(mailFormat)) {
        toast.error("Please enter valid email");
      } else {
        const data = {
          email: email,
        };
        const res = await HomeServices.addEmail(data)
        console.log("sbdfje1001",res)
        if(res && res.status){
          toast.success("Email Sent Successfully");
          setUserEmail(emailField)
        } else {
          toast.error(res?.message || "Server error")
        }
      }
    } else {
    toast.error("Please fill the Input")
    }
  };

  return (
    <>
      <section className="subscribe_main_section">
        <div className="cust_container">
          <div
            className="sbscrb_mainimg"
            style={{ backgroundImage: `url(${abtvct})` }}
          >
            <div className="bg_overlay">
              <div className="srcvgdiv">
                <h4 className="txtsbscrd">News Letter</h4>
                <p className="txtsbscrdpp">
                Subscribe for our Newsletter and get the Latest Updates about Canadian Immigration
                </p>
                <div className="ftr_email_mn">
                  <input
                    type="text"
                    id=""
                    name="email"
                    value={userEmail?.email}
                    onChange={handleEmail}
                    placeholder="Your Email"
                  />
                  <div className="email_send" onClick={onSubmit}>
                    send
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="foter_main">
        <div className="container-fluid">
          <div className="ftr_flx">
            <div className="ftr_logo_para_cntnt">
              <div className="lghdiv">
                <Link to="/" className="logo_hd_mn">
                  <img src={deshvideshimmigration} alt="..." />
                  <p className="deshtxt">DeshVidesh Immigration</p>
                </Link>
              </div>
              <div className="ftr_txt">
                <h4>DeshVidesh Immigration</h4>
                <p>
                  Immigration is serving clients from all over the world.
                  Immigration process can be very intimidating. Let us help you
                  get started with your application process. We help you at
                  every step of your application process.
                </p>
              </div>
            </div>

            <div className="imgrtn_flx_txt_auick">
              <p className="ftr_txtppp">Quick Links</p>
              <div className="imgrtn_flx_ftr">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to="/about-us">About</Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to="/">Immigration Programs</Link>
                  </li>
                </ul>

                <ul>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="imgrtn_flx_txt_flw">
              <p className="ftr_txtppp">Follow Us</p>
              <div className="imgrtn_flx_ftr"> 
                <ul>
                  <li>
                    <Link to="https://www.youtube.com/@OfficialSujeetDahiya ">
                      <i class="fa-brands fa-youtube"></i>
                      <span className="ftr_itm_cnt"> You Tube </span>
                    </Link>
                  </li>
                </ul>

                <ul>
                  <li>
                    <Link to="https://www.instagram.com/deshvideshimmigration?igsh=N3BxdGExM3Bhdjdp">
                      <i class="fa-brands fa-instagram"></i>
                      <span className="ftr_itm_cnt">Instagram</span>
                    </Link>
                  </li>
                </ul>

                <ul>
                  <li>
                    <Link to="https://www.tiktok.com/@deshvideshimmigration?_t=8jpEWGBgCxi&_r=1">
                      <i class="fa-brands fa-tiktok"></i>
                      <span class="ftr_itm_cnt"> Tik Tok </span>
                    </Link>
                  </li>
                </ul>

              </div>
            </div>
            <div className="imgrtn_flx_txt_info">
              <p className="ftr_txtppp">For More Info</p>
              <div className="imgrtn_flx_ftr">
                <ul>
                  <li className="phtftr">
                    <i class="fa-solid fa-phone"></i>
                    <span class="ftr_itm_cnt">+1 (548) 994-9465</span> 
                  </li>
                  <li className="phtftr">
                    <i class="fa-solid fa-phone"></i>
                    <span class="ftr_itm_cnt">+1 (519) 998-1002</span>
                  </li>
                </ul>
                <ul>
                  <li className="phtftr">
                    <i class="fa-solid fa-envelope"></i> 
                    <span class="ftr_itm_cnt">Support@deshvidesh.ca</span>
                  </li>
                </ul> 
              </div>
            </div>
          </div>
        </div>
      </section> 
      <ScrollTop />
    </>
  );
};

export default Footer;
