import React from 'react'
import InnerBanner from '../../Component/InnerBanner'
import ImmigrationList from '../../Component/ImmigrateProgramms/ImmigrationList'
import Allimmigrate from '../../Component/ImmigrateProgramms/Allimmigrate'
import student from "../../Image/student.jpg"
import Limaimmigrate from '../../Component/ImmigrateProgramms/Limaimmigrate'
import ImmigrationForm from '../../Component/ImmigrateProgramms/ImmigrationForm'
import Limabenefits from '../../Component/ImmigrateProgramms/Limabenefits'
const Lmia = () => {
    return (
        <>
            <section className='immigrate_section_main'>
                <InnerBanner InBanHead="LMIA" InBanLi="Lmia" />
                <div className='cust_container'>
                    <div className='row allctctdiv'>
                        <Limaimmigrate />
                        <ImmigrationList />

                    </div>
                    <Limabenefits/>
                </div>
                <ImmigrationForm anstr="If you are a Canadian employer who wants to hire a foreign worker through the LMIA program, you can start by checking your eligibility and choosing the stream and category that suits you. Connect with Desh Videsh Immigration Teamfor applying." text="The LMIA program is a great option for Canadian employers and foreign workers who want to benefit from the Canadian labour market and contribute to the diversity and prosperity of this great country. By applying for the LMIA program, you can achieve your employment goals and enjoy the rights and responsibilities that come with it." />
            </section>
        </>
    )
}

export default Lmia
