import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import ColorContext from "../Component/context/ColorContext";

const MainLayout = () => {

  return (
    <ColorContext>
    <>
      <Header  />
      <div className="">
        <Outlet />
      </div>
      <Footer />
    </>
    </ColorContext>
  );
};

export default MainLayout;
