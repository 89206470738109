import React, { useEffect, useState } from "react";
import "./Contact.css";
import InnerBanner from "../../Component/InnerBanner";
import Address from "../../Component/Contact/Address";
import HowcanMeetForm from "../BookAppoinment/HowcanMeetForm";
import CustomLoader from "../../loader/CustomLoader";
const Contact = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);
  return (
    <>
      <CustomLoader loading={isLoading} />
      <InnerBanner InBanHead="CONTACT US" InBanLi="Contact Us" />

      <Address />
      <div className="cust_container mt-3">
        <HowcanMeetForm />
      </div> 
    </>
  );
};

export default Contact;
