import React from 'react'
import InnerBanner from '../../Component/InnerBanner'
import ImmigrationList from '../../Component/ImmigrateProgramms/ImmigrationList'
// import Allimmigrate from '../../Component/ImmigrateProgramms/Allimmigrate'
// import student from "../../Image/student.jpg"
import Expresslist from '../../Component/ImmigrateProgramms/Expresslist'
import ExpressEntrybenefits from '../../Component/ImmigrateProgramms/ExpressEntrybenefits'
import ImmigrationForm from '../../Component/ImmigrateProgramms/ImmigrationForm'
const Expressentry = () => {
    return (
        <>
            <section className='immigrate_section_main'>
                <InnerBanner InBanHead="EXPRESS ENTRY" InBanLi="Express Entry" />
                <div className='cust_container'>
                    <div className='row allctctdiv'>
                         <Expresslist/>
                        <ImmigrationList /> 
              
                    </div>
                    <ExpressEntrybenefits/>
        
                </div>
                <ImmigrationForm anstr="If you are interested in applying for permanent residence in Canada through Express Entry, you can start by checking your eligibility and choosing the program that suits you. Connect with Desh Videsh Immigration Team." text="Express Entry is a great opportunity for skilled workers who want to immigrate to Canada and enjoy the rights and responsibilities that come with permanent residence. By applying through Express Entry, you can achieve your immigration goals and contribute to the diversity and prosperity of this great country."
                  />
            </section>
        </>
    )
}

export default Expressentry
