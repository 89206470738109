import React from "react";
import { Link } from "react-router-dom";
import background from "../Image/banner11.jpg";

function InnerBanner({ InBanHead, InBanLi }) {
  return (
    <>
      <section
        className="innerBanner_sec"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="overlay"></div>
        <div className="cust_container">
          <div className="content_wrap">
            <h2 className="head">{InBanHead}</h2>

            <div className="breadcrumb">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="ml-1 mr-1">/</li>
                <li>{InBanLi}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default InnerBanner;
