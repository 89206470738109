import HttpClientXml from "../utils/HttpClientXml";

const addContact = async (data) => {
  let endPoint = "add-contactus";
  return HttpClientXml.post(endPoint, data);
};

const getBlog = async (data) => {
  let endPoint = "view-blog";
  return HttpClientXml.get(endPoint, data);
};

const addEmail = async (data) => {
  let endPoint = "add-email";
  return HttpClientXml.post(endPoint, data);
};

const getTime = async (data) => {
  let endPoint = "view-timing";
  return HttpClientXml.get(endPoint, data);
};

const bookAppointment = async (data) => {
  let endPoint = "servicewise-appointment";
  return HttpClientXml.post(endPoint, data);
};

const addAppointment = async (data) => {
  let endPoint = "add-bookappointment";
  return HttpClientXml.post(endPoint, data);
};
const viewCountry = async () => {
  let endPoint = "view-country";
  return HttpClientXml.get(endPoint);
};

const addWaitList = async (data) => {
  let endPoint = "add-bookwaitlist";
  return HttpClientXml.post(endPoint, data);
};

const availableTimeByDate = async (data) => {
  let endPoint = "availabletime-by-date";
  return HttpClientXml.post(endPoint, data);
};

const makePayment = async(data) =>{
  let endPoint ="create-checkout-session";
  return HttpClientXml.post(endPoint, data)
}

const viewBlockDateTime = async(data) =>{
  let endPoint ="view-blockdatetime";
  return HttpClientXml.post(endPoint, data)
}

const viewUrgentBlockDateTime = async(data) =>{
  let endPoint ="view-urgent-blockdatetime";
  return HttpClientXml.post(endPoint, data)
}
// view-urgent-blockdatetime
const viewBlockDate = async() =>{
  let endPoint ="view-blockdate";
  return HttpClientXml.get(endPoint)
}

const viewUrgentBlockDate = async() =>{
  let endPoint ="view-urgent-blockdate";
  return HttpClientXml.get(endPoint)
} 

const initialBookingAppointment = async(data) =>{
  let endPoint ="booking-appointment-initial";
  return HttpClientXml.post(endPoint, data)
}
 
export default {
  getTime,
  getBlog,
  addContact,
  bookAppointment,
  addAppointment,
  viewCountry,
  addWaitList,
  addEmail,
  availableTimeByDate,
  makePayment,
  viewBlockDateTime,
  viewUrgentBlockDateTime,
  viewBlockDate,
  viewUrgentBlockDate,
  initialBookingAppointment
};
