import React from 'react'
import InnerBanner from '../../Component/InnerBanner'
import ImmigrationList from '../../Component/ImmigrateProgramms/ImmigrationList'
import Allimmigrate from '../../Component/ImmigrateProgramms/Allimmigrate'
import student from "../../Image/student.jpg"
import VisitorVisalist from '../../Component/ImmigrateProgramms/VisitorVisalist'
import ImmigrationForm from '../../Component/ImmigrateProgramms/ImmigrationForm'
const VisitorVisa = () => {
  return (
   <>
      <section className='immigrate_section_main'>
                <InnerBanner InBanHead="VISITOR VISA" InBanLi="Visitor Visa" />
                <div className='cust_container'>
                    <div className='row allctctdiv'>
                    <VisitorVisalist/>
                        <ImmigrationList />
                        
                    </div>
                </div>
                <ImmigrationForm anstr="If You Want to apply for Canada Visitor Visa than connect with Desh Videsh Immigration Team for better guidance. "/>
            </section>
   </>
  )
}

export default VisitorVisa
