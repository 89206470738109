import React from 'react'
const ImmigrationDesc = () => {
    return (
        <>
            <div className='alllistdiv'>
                <div className='alllistdivft'>
                    <p className='alllistppptxt'>To become a Canadian citizen, you must meet certain eligibility criteria, such as:</p>
                    <ul className='allllist'>
                        <li className='ctctxtpp'>Being a permanent resident of Canada</li>
                        <li className='ctctxtpp'>Having lived in Canada for at least 3 out of the last 5 years</li>
                        <li className='ctctxtpp'>Having filed your taxes, if you need to</li>
                        <li className='ctctxtpp'>Proving your language skills in English or French</li>
                        <li className='ctctxtpp'>Passing a citizenship test on your rights, responsibilities, and knowledge of Canada</li>
                        <li className='ctctxtpp'>Passing a citizenship test on your rights, responsibilities, and knowledge of Canada</li>
                        <li className='ctctxtpp'>Taking the oath of citizenship at a ceremony</li>
                    </ul>
                </div>
                <div className='alllistdivft'>
                    <p className='alllistppptxt'>There are different application packages depending on your situation, such as:</p>
                    <ul className='allllist'>
                        <li className='ctctxtpp'>Being a permanent resident of Canada</li>
                        <li className='ctctxtpp'>Having lived in Canada for at least 3 out of the last 5 years</li>
                        <li className='ctctxtpp'>Having filed your taxes, if you need to</li>
                        <li className='ctctxtpp'>Proving your language skills in English or French</li>
                        <li className='ctctxtpp'>Passing a citizenship test on your rights, responsibilities, and knowledge of Canada</li>
                        <li className='ctctxtpp'>Passing a citizenship test on your rights, responsibilities, and knowledge of Canada</li>
                        <li className='ctctxtpp'>Taking the oath of citizenship at a ceremony</li>
                    </ul>
                </div>

            </div>
        </>

    )
}

export default ImmigrationDesc
