import React, { useContext, useEffect } from "react";
import deshvideshimmigration from "../../Image/deshvideshimmigration.png";
import toast from "react-hot-toast";
import "./BookAppointment.css";
import { Colorcontext } from "../../Component/context/ColorContext";
const ProceedToPay = () => {
    const colordata = useContext(Colorcontext);
  useEffect(() => {
    colordata.setcolor(true);
    return () => {
      colordata.setcolor(false);
    };
  });

  return (
    <>
      <section className="bookAppoSection">
        <div className="cust_container">
          <p className="bookAppHead">Make Payment</p>
          <div className="immiAllDivsMain">
            <div className="lghdiv">
              <figure Link to="/" className="logo_hd_mn">
                <img src={deshvideshimmigration} alt="..." />
                <p className="deshtxt">DeshVidesh Immigration</p>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProceedToPay;
