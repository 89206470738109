import React from 'react'
import { Link } from 'react-router-dom'
import studentvise from "../../Image/studentvise.jpg";
import abtvct from "../../Image/abtvct.png"
const ImmigrationList = () => {
  const immigratedetails = [
    {
      name: "Citizenship",
      Link: "/citizenship",
    },
    {
      name: "Permanent Residence",
      Link: "/permanentresidence",
    },
    {
      name: "Express Entry",
      Link: "/expressentry",
    },
    {
      name: "LMIA",
      Link: "/lmia",
    },
    {
      name: "Work Permit",
      Link: "/workpermit",
    },
    {
      name: "Spousal Sponsorship",
      Link: "/spousalSponsorship",
    },
    {
      name: "Canada Visitor Visa",
      Link: "/visitorVisa",
    },
    {
      name: "Student Visa",
      Link: "/studentVisa",
    },
  ]
  return (
    <>
      <div className='col-lg-4 col-xl-4 col-md-6 col-12'>
        <div className='imigratebrdrdiv'>
          <div className='imigratebrdrdivdtls'>
            {immigratedetails.map((item, index) => {
              return (
                <div className='imgdivbrdr' key={index}>
                  <Link to={item.Link} className='itmtxt'>
                    {item.name}
                  </Link>
                  <div className='arrwicn'>
                    <i className="fa-solid fa-angles-right"></i>
                  </div>
                </div>
              )

            })}
          </div>
        </div>
        {/* <div className='imgstdnt'>
          <img src={studentvise} alt="..." />
        </div> */}
        {/* <div className='imgstdnt'>
          <img src={abtvct} alt="..." />
        </div>
        <div className='imgstdnt'>
        <img src={abtvct} alt="..." />
        </div> */}
      </div>
    </>
  )
}

export default ImmigrationList
