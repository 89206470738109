import React from 'react'

const ExpressEntrybenefits = () => {
  return (
       <section className='exprssbenfits'>
        <div className='exprsgg'>
            <h4 className='cndatrpp algn'> What are the benefits of Express Entry?</h4>
            <p className='ptxtcppas'>Express Entry has many benefits for skilled workers who want to immigrate to Canada, such as:</p>
            <div>
            <ul className='ullistpp'>
                        <li className='othlstdipp'>Faster processing times. Express Entry applications are processed within six months or less, which is much faster than other immigration programs. You can also update your profile at any time and improve your score and chances of being invited.</li>
                        <li className='othlstdipp'>More opportunities and flexibility. Express Entry manages applications for three federal economic immigration programs: Federal Skilled Worker Program, Federal Skilled Trades Program, and Canadian Experience Class. You can choose the program that best suits your qualifications and goals. You can also apply through Express Entry for the Provincial Nominee Program, which allows provinces and territories to nominate immigrants who have the skills, education, and work experience to contribute to their economy and society. If you are nominated, you will get extra points and be invited to apply quickly.</li>
                        <li className='othlstdipp'>Online and user-friendly system. Express Entry is an online system that is easy to use and accessible from anywhere in the world. You can create your profile, submit your application, check your status, and receive notifications online. You can also use Job Bank, a free online tool that connects you with Canadian employers who are looking for workers like you.</li>
                    </ul>
            </div>
        </div>
       </section>
  )
}

export default ExpressEntrybenefits
